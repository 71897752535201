import React, { useEffect } from "react";

import Main from '../components/Trading/Main'
import Why from "../components/Trading/Why";
import Diverse from "../components/Trading/Diverse";
import Comprehensive from "../components/Trading/Comprehensive";
import AWide from "../components/Trading/AWide";
import More from "../components/Trading/More";
import Regulation from "../components/Trading/Regulation";

function Trading () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Why/>
            <Diverse/>
            <Comprehensive/>
            <AWide/>
            <More/>
            <Regulation/>
        </div>
    );
}

export default Trading;
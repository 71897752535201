import React from "react";

import { Link } from "react-router-dom";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

import lis1 from '../../img/Home/lis1.png'
import lis2 from '../../img/Home/lis2.png'
import lis3 from '../../img/Home/lis3.png'

import Fade from 'react-reveal/Fade'

function List () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto">
           <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="flex flex-col justify-between gap-[20px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('home.20')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.21')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] italic font-[500]">{t('home.22')}</p>
                    <Link to="/listing">
                        <div className="button_main flex cursor-pointer bg-[#00685E] mt-[60px]">
                            <p className="uppercase roboto font-[500] text-[16px] text-[#FFF]">{t('home.31')}</p>
                        </div>
                    </Link>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <Fade right><img src={lis1} alt="" className="mx-auto"/></Fade>
                </div>
           </div>
           <div className="flex flex-col xl:flex-row-reverse justify-between pt-[80px] xl:pt-[160px]">
                <div className="flex flex-col justify-between gap-[20px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('home.23')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.24')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.25')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.26')}</p>
                    <Link to="/trading">
                        <div className="button_main flex cursor-pointer bg-[#00685E] mt-[60px]">
                            <p className="uppercase roboto font-[500] text-[16px] text-[#FFF]">{t('home.31')}</p>
                        </div>
                    </Link>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <Fade left><img src={lis2} alt="" className="mx-auto"/></Fade>
                </div>
           </div>
           <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px] pb-[80px] xl:pb-[160px]">
                <div className="flex flex-col justify-between gap-[20px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('home.27')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.28')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.29')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.30')}</p>
                    <Link to="/investing">
                        <div className="button_main flex cursor-pointer bg-[#00685E] mt-[60px]">
                            <p className="uppercase roboto font-[500] text-[16px] text-[#FFF]">{t('home.31')}</p>
                        </div>
                    </Link>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <Fade right><img src={lis3} alt="" className="mx-auto"/></Fade>
                </div>
           </div>
        </div>
    );
}

export default List;
import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import lis1 from '../../img/Raise/1.png'

import Fade from 'react-reveal/Fade'

function Dependable () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto">
           <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="flex flex-col justify-between gap-[20px] xl:max-w-[517px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.4')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('raise.5')}</p>
                    <Button color="#00685E" textcolor="#FFF" text={t('raise.6')}/>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <Fade right><img src={lis1} alt="" className="mx-auto"/></Fade>
                </div>
           </div>
        </div>
    );
}

export default Dependable;
import React, { useEffect } from "react";

import Main from '../components/Raise/Main'
import Dependable from "../components/Raise/Dependable";
import Equity from "../components/Raise/Equity";
import Introducing from "../components/Raise/Introducing";
import Markets from "../components/Raise/Markets";
import Howtogopublic from "../components/Raise/Howtogopublic";
import Targeting from "../components/Raise/Targeting";
import HowSell from "../components/Raise/HowSell";
import Bond from "../components/Raise/Bond";
import TheWorld from "../components/Raise/TheWorld";
import Enxbmarkets from "../components/Raise/Enxbmarkets";
import Support from "../components/Raise/Support";
function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Dependable/>
            <Equity/>
            <Introducing/>
            <Markets/>
            <Howtogopublic/>
            <Targeting/>
            <HowSell/>
            <Bond/>
            <TheWorld/>
            <Enxbmarkets/>
            <Support/>
        </div>
    );
}

export default Home;
import React from "react";

import { useTranslation } from "react-i18next";

import why from '../../img/Trading/why.png'

import Fade from 'react-reveal/Fade'

function Why () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px]">
            <div>
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('trading.5')}</p>
                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('trading.6')}</p>
                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('trading.7')}</p>
                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('trading.8')}</p>
                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('trading.9')}</p>
                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('trading.10')}</p>
            </div>
            <div>
                <Fade right><img src={why} alt="" /></Fade>
            </div>
        </div>
    );
}

export default Why;
import React from "react";

import { useTranslation } from "react-i18next";

function AWide () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('trading.27')}</p>
                </div>
                <div>
                    <p className="xl:max-w-[596px] text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px] xl:mt-0">{t('trading.28')}</p>
                </div>
            </div>
            <div className="pt-[20px] xl:pt-[60px] flex flex-wrap gap-[20px] justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex-grow">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.38')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.39')}</p>
                </div>
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex-grow">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.36')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.37')}</p>
                </div>
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex-grow">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.34')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.35')}</p>
                </div>
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex-grow">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.32')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.33')}</p>
                </div>
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex-grow">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.29')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.30')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.31')}</p>
                </div>
            </div>
        </div>
    );
}

export default AWide;
import React from "react";

import { useTranslation } from "react-i18next";

import why from '../../img/Trading/why.png'

function Diverse () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('trading.11')}</p>
                </div>
                <div>
                    <p className="xl:max-w-[596px] text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px] xl:mt-0">{t('trading.12')}</p>
                </div>
            </div>
            <div className="pt-[20px] xl:pt-[60px] flex flex-col gap-[20px] xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex flex-col">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.13')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.14')}</p>
                </div>
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex flex-col">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.15')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.16')}</p>
                </div>
                <div className="w-[394px] h-[200px] xl:h-[251px] bg-[#EBF6F5] p-[20px] xl:p-[40px] flex flex-col">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.17')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px]">{t('trading.18')}</p>
                </div>
            </div>
        </div>
    );
}

export default Diverse;
import React, { useEffect, useState } from "react";
import $ from 'jquery';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { images } from "./imagesToLoad";

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Raise from "./pages/Raise";
import Listing from "./pages/Listing";
import Trading from "./pages/Trading";
import Investing from "./pages/Investing";
import About from "./pages/About";
import Media from "./pages/Media";
import Regulaion from "./pages/Regulation";

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 
  return (
    <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/raise" element={<Raise />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/trading" element={<Trading />} />
          <Route path="/investing" element={<Investing />} />
          <Route path="/about" element={<About />} />
          <Route path="/media" element={<Media />} />
          <Route path="/regulation" element={<Regulaion />} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import gr from '../../img/About/group.png';

function OurHistory() {
    const { t } = useTranslation();

    const data = [
        {
            year: '2024',
            content: ['about.75', 'about.76', 'about.77', 'about.78', 'about.79', 'about.80']
        },
        {
            year: '2023',
            content: ['about.a.1', 'about.a.2',]
        },
        {
            year: '2022',
            content: ['about.a.3', 'about.a.4', 'about.a.5']
        },
        {
            year: '2021',
            content: ['about.a.6', 'about.a.7']
        },
        {
            year: '2020',
            content: ['about.a.8']
        },
        {
            year: '2019',
            content: ['about.a.9']
        },
        {
            year: '2018',
            content: ['about.a.10']
        },
        {
            year: '2017',
            content: ['about.a.11']
        },
        {
            year: '2016',
            content: ['about.a.12']
        },
        {
            year: '2015',
            content: ['about.a.13']
        },
    ];

    const [selectedYear, setSelectedYear] = useState(data[0].year);

    const selectedContent = data.find(item => item.year === selectedYear)?.content || [];

    return (
        <div className="relative mt-[80px] xl:mt-[160px] overflow-hidden max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col">
            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px] mx-auto">
                {t('about.74')}
            </p>
            <div className="mt-[60px] h-[2px] bggraout hidden xl:block"></div>
            <div className="mt-[60px] xl:mt-[15px] items-center flex">
                <div className={`flex flex-wrap gap-[30px] xl:gap-[50px] mx-auto`}>
                    {data.map(item => (
                        <div className="flex-col flex items-center">
                            <svg className={`mt-[-20px] ${item.year === selectedYear ? 'hidden' : 'hidden xl:block'}`} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="3.5" fill="white" stroke="#00685E"/>
                            </svg>
                            <svg className={`mt-[-20px] ${item.year === selectedYear ? 'hidden xl:block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#00685E"/>
                            </svg>
                            <div className={`mt-[20px] ${item.year === selectedYear ? 'w-[80px] h-[35px] bg-[#00685E] flex items-center justify-center' : ''}`}>
                                <p 
                                    key={item.year} 
                                    className={`roboto font-[500] text-[20px] cursor-pointer ${item.year === selectedYear ? 'text-white' : 'text-[#00685E]'}`} 
                                    onClick={() => setSelectedYear(item.year)}
                                >
                                    {item.year}
                                </p>
                            </div>
                        </div>
                        
                    ))}
                </div>
            </div>
            <div className="mt-[30px] xl:mt-[60px]">
                {selectedContent.slice(0).map((contentKey, index) => (
                    <p key={index} className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]">
                        <span className="mr-[15px] text-[#00685E]">●</span>{t(contentKey)}
                    </p>
                ))}
            </div>
        </div>
    );
}

export default OurHistory;

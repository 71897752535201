import React from "react";

import { useTranslation, Trans } from "react-i18next";

import { Link } from "react-router-dom";

import money from '../../img/Home/money.png'

import Button from '../Button'

function Raise () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative bg-[#00685E] xl:h-[557px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row pt-[40px] xl:pt-[80px] justify-between relative">
                <div>
                    <img src={money} alt="" className="absolute bottom-0 right-0 xl:left-[-70px]"/>
                    <p className="text-[20px] xl:text-[40px] text-white leading-[1.2] roboto">{t('home.32')}</p>
                    <p className="mt-[12px] roboto text-[16px] xl:text-[24px] font-[500] text-[#FFFFFFB2] xl:max-w-[364px]">{t('home.33')}</p>
                </div> 
                <div className="mt-[20px] xl:mt-0 xl:pb-[100px]">
                    <p className="xl:max-w-[600px] text-[16px] xl:text-[20px] roboto text-[#FFFFFFB2]">{t('home.34')}</p>
                    <p className="xl:max-w-[600px] text-[16px] xl:text-[20px] roboto text-[#FFFFFFB2] font-[500] italic mt-[20px]">{t('home.35')}</p>
                    <div className=" mt-[20px] xl:mt-[40px] pb-[250px] sm:pb-[320px] xl:pb-0">
                        <Link to="/raise">
                            <div className="button_main flex cursor-pointer bg-[#FFF]">
                                <p className="uppercase roboto font-[500] text-[16px] text-[#000]">{t('home.31')}</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Raise;
import React from "react";

import { useTranslation } from "react-i18next";

import s1 from '../../img/Trading/s1.png'
import s2 from '../../img/Trading/s2.png'

function Brokers () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto pb-[80px] xl:pb-0">
            <div className="flex flex-col xl:flex-row justify-between xl:h-[400px]">
                <div className="xl:w-[600px]">
                    <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                        <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('investing.43')}</p>
                    </div>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.44')}</p>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.45')}</p>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.46')}</p>
                </div>
                <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                    <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                        <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('investing.47')}</p>
                    </div>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.48')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between xl:h-[400px] mt-[20px] xl:mt-0">
                <div className="xl:w-[600px]">
                    <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                        <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('investing.49')}</p>
                    </div>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.50')}</p>
                </div>
                <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                    <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                        <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('investing.51')}</p>
                    </div>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.52')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between xl:h-[400px] mt-[20px] xl:mt-0">
                <div className="xl:w-[600px]">
                    <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                        <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('investing.53')}</p>
                    </div>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.54')}</p>
                </div>
                <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                    <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                        <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('investing.55')}</p>
                    </div>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('investing.56')}</p>
                </div>
            </div>
        </div>
    );
}

export default Brokers;
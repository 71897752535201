import React from "react";

import { useTranslation } from "react-i18next";

import phone from '../../img/About/phone.png'

import Fade from 'react-reveal/Fade'

function Leading () {

    const { t } = useTranslation();
      
    return(
        <div className="relative bg-[#00685E] xl:h-[400px] mt-[80px] xl:mt-[160px] pt-[40px] xl:py-[80px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <p className="text-[#FFF] roboto text-[24px] xl:text-[48px] leading-[1.2]">{t('about.30')}</p>
                <p className="text-[#FFFFFF90] roboto text-[20px] xl:text-[24px] mt-[20px] xl:mt-[40px] max-w-[596px]">{t('about.31')}</p>
                <Fade right><img src={phone} alt="" className="xl:absolute bottom-0 xl:right-1/4 mt-[20px] xl:mt-0"/></Fade>
            </div>
        </div>
    );
}

export default Leading;
import React from "react";

import { useTranslation } from "react-i18next";

import image from '../../img/Regulation/image.png'

function SeeReg () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative bg-[#EBF6F5] xl:h-[245px] mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between py-[20px] xl:py-[60px]">
                <div>
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px] text-center xl:text-left">{t('regulation.9')}</p>
                </div>
                <div className="flex flex-col xl:flex-row gap-[20px] mt-[20px] mx-auto">
                    <div className="flex flex-col gap-[20px]">
                        <div className="see">
                            <p className="text-[#00685E] text-[14px] xl:text-[16px] font-[500] uppercase roboto">{t('footer.5')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_5035_147)">
                                    <path d="M16.5938 10.9922V15.8789C16.5938 16.2666 16.2783 16.582 15.8906 16.582H2.10938C1.72167 16.582 1.40625 16.2666 1.40625 15.8789V10.9922H0V15.8789C0 17.042 0.946266 17.9883 2.10938 17.9883H15.8906C17.0537 17.9883 18 17.042 18 15.8789V10.9922H16.5938Z" fill="#00685E"/>
                                    <path d="M9 0.0117188L4.63065 4.38108L5.625 5.37544L8.29688 2.70356V13.6623H9.70313V2.70356L12.375 5.37544L13.3694 4.38108L9 0.0117188Z" fill="#00685E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_147">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="see">
                            <p className="text-[#00685E] text-[14px] xl:text-[16px] font-[500] uppercase roboto">{t('footer.6')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_5035_147)">
                                    <path d="M16.5938 10.9922V15.8789C16.5938 16.2666 16.2783 16.582 15.8906 16.582H2.10938C1.72167 16.582 1.40625 16.2666 1.40625 15.8789V10.9922H0V15.8789C0 17.042 0.946266 17.9883 2.10938 17.9883H15.8906C17.0537 17.9883 18 17.042 18 15.8789V10.9922H16.5938Z" fill="#00685E"/>
                                    <path d="M9 0.0117188L4.63065 4.38108L5.625 5.37544L8.29688 2.70356V13.6623H9.70313V2.70356L12.375 5.37544L13.3694 4.38108L9 0.0117188Z" fill="#00685E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_147">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[20px]">
                        <div className="see">
                            <p className="text-[#00685E] text-[14px] xl:text-[16px] font-[500] uppercase roboto">{t('footer.7')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_5035_147)">
                                    <path d="M16.5938 10.9922V15.8789C16.5938 16.2666 16.2783 16.582 15.8906 16.582H2.10938C1.72167 16.582 1.40625 16.2666 1.40625 15.8789V10.9922H0V15.8789C0 17.042 0.946266 17.9883 2.10938 17.9883H15.8906C17.0537 17.9883 18 17.042 18 15.8789V10.9922H16.5938Z" fill="#00685E"/>
                                    <path d="M9 0.0117188L4.63065 4.38108L5.625 5.37544L8.29688 2.70356V13.6623H9.70313V2.70356L12.375 5.37544L13.3694 4.38108L9 0.0117188Z" fill="#00685E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_147">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="see">
                            <p className="text-[#00685E] text-[14px] xl:text-[16px] font-[500] uppercase roboto">{t('footer.8')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_5035_147)">
                                    <path d="M16.5938 10.9922V15.8789C16.5938 16.2666 16.2783 16.582 15.8906 16.582H2.10938C1.72167 16.582 1.40625 16.2666 1.40625 15.8789V10.9922H0V15.8789C0 17.042 0.946266 17.9883 2.10938 17.9883H15.8906C17.0537 17.9883 18 17.042 18 15.8789V10.9922H16.5938Z" fill="#00685E"/>
                                    <path d="M9 0.0117188L4.63065 4.38108L5.625 5.37544L8.29688 2.70356V13.6623H9.70313V2.70356L12.375 5.37544L13.3694 4.38108L9 0.0117188Z" fill="#00685E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_147">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SeeReg;
import React from "react";

import { useTranslation } from "react-i18next";

import s1 from '../../img/Trading/s1.png'
import s2 from '../../img/Trading/s2.png'

function Safe () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[572px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('investing.23')}</p>
                    <p className="mt-[20px] xl:mt-[40px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.24')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.25')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.26')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.27')}</p>
                    <img src={s1} alt="" className="mt-[20px] xl:mt-[60px]"/>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:w-[600px] bg-[#EBF6F5] p-[20px] xl:p-[40px]">
                    <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('investing.28')}</p>
                    <p className="mt-[20px] xl:mt-[40px] roboto text-[16px] xl:text-[20px] font-[500] text-[#000000B2]">{t('investing.29')}</p>
                    <p className="mt-[20px] roboto text-[#000000B2] text-[14px] xl:text-[16px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.30')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[14px] xl:text-[16px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.31')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[14px] xl:text-[16px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.32')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[14px] xl:text-[16px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.33')}</p>
                    <p className="mt-[30px] italic text-[16px] xl:text-[20px] roboto text-[#00685E]">{t('investing.34')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="xl:max-w-[572px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('investing.35')}</p>
                    <p className="mt-[20px] xl:mt-[40px] roboto text-[#000000B2] text-[16px] xl:text-[20px]">{t('investing.36')}</p>
                    <p className="mt-[20px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.37')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.38')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.39')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.40')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.41')}</p>
                    <p className="mt-[10px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('investing.42')}</p>
                </div>
                <img src={s2} alt="" />
            </div>
        </div>
    );
}

export default Safe;
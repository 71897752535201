import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import checkIcon from '../../img/Home/yes.svg';
import crossIcon from '../../img/Home/no.svg';

const tableData = {
    headers: [
        "", 
        "tablehome.starter", 
        "tablehome.brown", 
        "tablehome.silver", 
        "tablehome.gold", 
        "tablehome.platinum", 
        "tablehome.vip"
    ],
    rows: [
        ["tablehome.minDeposit", "250 $", "500 $", "1,000 $", "5,000 $", "10,000 $", "50,000 $"],
        ["tablehome.activation", "tablehome.managerHelp", "tablehome.managerHelp", "tablehome.managerHelp", "tablehome.managerHelp", "tablehome.managerHelp", "tablehome.managerHelp"],
        ["tablehome.assets", "tablehome.forex", "tablehome.rawMaterials", "tablehome.sharesIndices", "tablehome.cryptocurrencies", "tablehome.investmentPortfolios", "tablehome.tradingMonitoring"],
        ["tablehome.vipService", "✘", "✘", "tablehome.generalInfoVIP", "tablehome.unitedTradingOpportunity", "tablehome.personalizedCommerce", "tablehome.investmentPlan"],
        ["tablehome.speedy", "tablehome.standard", "tablehome.mediocre", "tablehome.mediocre", "tablehome.short", "tablehome.short", "tablehome.short"],
        ["tablehome.currency", "✔", "✔", "✔", "✔", "✔", "✔"],
        ["tablehome.goods", "✘", "✘", "✔", "✔", "✔", "✔"],
        ["tablehome.sharesIndices", "✘", "✔", "tablehome.analystRequest", "✔", "✔", "✔"],
        ["tablehome.tradingSignals", "✘", "✔", "✔", "✔", "✔", "✔"],
        ["tablehome.smsAlerts", "✘", "tablehome.analystRequest", "tablehome.analystRequest", "✔", "✔", "✔"],
        ["tablehome.tradeFocusGroups", "✘", "✘", "tablehome.analystRequest", "tablehome.analystRequest", "✔", "✔"],
        ["tablehome.extendTradeExpiration", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest"],
        ["tablehome.saveAccount", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest", "tablehome.analystRequest"],
        ["tablehome.quarterlyReports", "✘", "✘", "tablehome.analystRequest", "✔", "✔", "✔"],
        ["tablehome.webinars", "tablehome.forBeginners", "tablehome.educationWebinar", "tablehome.tradingLessons", "tablehome.marketEvents", "tablehome.webinarsGuests", "tablehome.privateVipWebinars"],
    ],
};

function AccountTypes() {
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px] pb-[80px] xl:pb-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('home.36')}</p>
                <p className="max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('home.37')}</p>

                {/* Swiper for mobile table */}
                <div className="block xl:hidden mt-[40px]">
                    <Swiper
                        spaceBetween={20}
                        breakpoints={{
                            640: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                        }}
                    >
                        {tableData.rows.map((row, rowIndex) => (
                            <SwiperSlide key={rowIndex} className="bg-[#EBF6F5] rounded-lg p-4">
                                <h4 className="font-bold mb-2 roboto text-[#00685E]">{t(row[0])}</h4>
                                <div className="flex flex-col">
                                    {row.slice(1).map((cell, cellIndex) => (
                                        <div key={cellIndex} className="flex justify-between mb-2">
                                            <span className="text-[#000000B2] roboto">{t(tableData.headers[cellIndex + 1])}</span>
                                            {cell === "✔" ? (
                                                <img src={checkIcon} alt="check" className="w-5 h-5" />
                                            ) : cell === "✘" ? (
                                                <img src={crossIcon} alt="cross" className="w-5 h-5" />
                                            ) : (
                                                <p className="roboto text-[#000000B2]">{t(cell)}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* Desktop table */}
                <div className="hidden xl:block pt-[60px]">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr className="bg-[#00685E]">
                                {tableData.headers.map((header, index) => (
                                    <th key={index} className="px-4 py-4">
                                        <p className="text-white roboto font-[500] text-[20px]">{t(header)}</p>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.rows.map((row, rowIndex) => (
                                <tr key={rowIndex} className={rowIndex % 2 === 0 ? "bg-white" : "bg-[#EBF6F5]"}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} className="px-4 py-2">
                                            {cell === "✔" ? (
                                                <img src={checkIcon} alt="check" className="w-5 h-5 mx-auto" />
                                            ) : cell === "✘" ? (
                                                <img src={crossIcon} alt="cross" className="w-5 h-5 mx-auto" />
                                            ) : (
                                                <p className="roboto text-[#000000B2] text-center">{t(cell)}</p>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex flex-col xl:flex-row justify-between mt-[40px] xl:mt-[80px]">
                    <div>
                        <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.38')}</p>
                        <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]">{t('home.39')}</p>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('home.40')}</p>
                        <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]">{t('home.41')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountTypes;

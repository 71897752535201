import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/Raise/img2.png'

function HowSell () {

    const { t } = useTranslation();
      
    return(
        <>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.68')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.69')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.70')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.71')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.72')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.73')}</p>
                </div>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.74')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.75')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.76')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.77')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.78')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.79')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.80')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.81')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.82')}</p>
                </div>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.83')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.84')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.85')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.86')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.87')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.88')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.89')}</p>
                </div>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.90')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400] xl:mt-[40px]">{t('raise.91')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.92')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={img} alt="" className="mx-auto"/>
                </div>
            </div>
        </>
    );
}

export default HowSell;
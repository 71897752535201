import React, { useEffect } from "react";

import Main from '../components/Regulation/Main'
import Regulatory from "../components/Regulation/Regulatory";
import SeeReg from "../components/Regulation/SeeReg";

function Regulaion () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Regulatory/>
            <SeeReg/>
        </div>
    );
}

export default Regulaion;
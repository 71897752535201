import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import arrow from '../../img/Raise/arrow.png'

const faqData = [
    {
        question: 'raise.21',
        answer: 'raise.22'
    },
    {
        question: 'raise.23',
        answer: 'raise.24'
    },
    {
        question: 'raise.25',
        answer: 'raise.26'
    },
]


function Introducing () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

      
    return(
        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
            <div>
                <p className="xl:max-w-[477px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.17')}</p>
                <p className="xl:max-w-[517px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('raise.18')}</p>
            </div>
            <div className="mt-[20px] xl:mt-0">
                <p className="xl:max-w-[477px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.20')}</p>
                <div className="xl:w-[600px] mt-[30px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div onClick={() => toggleText(index)} className={`flex justify-between px-[40px] mt-[10px] transition-all duration-300 bg-[#00685E] cursor-pointer ${openIndex === index ? 'h-auto' : 'h-[70px]'}`}>
                                <div>
                                    <p className='font-[500] mt-[25px] xl:mt-[17px] roboto text-[16px] xl:text-[24px] text-[#fff] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex === index && (
                                        <p className='text-[#FFFFFF90] open-sans text-[16px] xl:text-[20px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[500px]'>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mt-[27px]'>
                                    <img src={arrow} alt="" className={`${openIndex === index ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Introducing;
import React from "react";

import { useTranslation } from "react-i18next";

import image from '../../img/Regulation/image.png'

function Regulatory () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[600px] flex flex-col justify-between">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('regulation.5')}</p>
                    <img src={image} alt="" className="mt-[20px] xl:mt-0"/>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:max-w-[600px]">
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400]">{t('regulation.6')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('regulation.7')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('regulation.8')}</p>
                </div>
            </div>
        </div>
    );
}

export default Regulatory;
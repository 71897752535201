import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Investing/main.png'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative h-[662px]">
            <img src={main} alt="" className="w-full h-full object-cover"/>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="absolute top-[100px]">
                    <p className="text-white font-[500] text-[32px] xl:text-[64px] leading-[1.2]"><Trans>{t('investing.1')}</Trans></p>
                    <p className="text-[#FFFFFF] xl:max-w-[517px] mt-[20px] xl:mt-[60px] roboto text-[16px] xl:text-[32px]">{t('investing.2')}</p>
                    <p className="text-[#FFFFFFB2] xl:max-w-[517px] mt-[20px] roboto text-[16px] xl:text-[20px]">{t('investing.3')}</p>
                </div>
            </div>
        </div>
    );
}

export default Main;
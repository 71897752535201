import React from 'react';

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='w-full bg-[#00685E]'>
      <div className='max-w-[1280px] xl:mx-auto mx-[20px] pb-[40px] xl:pb-[80px]'>
        <div className='flex flex-col xl:flex-row pt-[30px] xl:pt-[80px] gap-[20px] xl:gap-[100px]'>
          <div className='flex flex-col justify-between'>
            <p className='text-[25px] xl:text-[40px] text-white roboto'>{t('footer.1')}</p>
            <div className='mt-[20px] xl:mt-0'>
              <div className='flex gap-[20px] items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M22.4503 17.3503C22.2057 17.0896 21.3417 16.319 19.7536 15.2995C18.1538 14.271 16.9739 13.6298 16.6355 13.4803C16.6057 13.467 16.5728 13.4622 16.5404 13.4662C16.5081 13.4703 16.4774 13.4831 16.4517 13.5032C15.9066 13.9284 14.9888 14.7093 14.9396 14.7515C14.6217 15.0238 14.6217 15.0238 14.3616 14.939C13.9041 14.789 12.4828 14.0343 11.2444 12.7931C10.006 11.5518 9.21237 10.0954 9.06237 9.63838C8.97659 9.37775 8.97659 9.37775 9.24987 9.05994C9.29206 9.01072 10.0735 8.09291 10.4986 7.54822C10.5188 7.52257 10.5316 7.49191 10.5356 7.45954C10.5397 7.42716 10.5348 7.3943 10.5216 7.36447C10.3721 7.02557 9.73081 5.84619 8.70237 4.24635C7.68143 2.65869 6.91174 1.79479 6.65112 1.5501C6.6272 1.52753 6.59764 1.51183 6.56555 1.50465C6.53346 1.49747 6.50003 1.49909 6.46878 1.50932C5.55775 1.8224 4.67875 2.22188 3.84378 2.70229C3.03772 3.17086 2.27461 3.70967 1.56331 4.31244C1.53848 4.33355 1.51986 4.36102 1.50946 4.3919C1.49905 4.42279 1.49725 4.45592 1.50424 4.48775C1.60221 4.94432 2.07049 6.85025 3.52362 9.49025C5.00628 12.1846 6.03378 13.5651 8.21112 15.7349C10.3885 17.9048 11.8125 18.9942 14.5097 20.4768C17.1497 21.9299 19.0566 22.3987 19.5122 22.4957C19.5441 22.5027 19.5773 22.5008 19.6082 22.4904C19.6392 22.48 19.6667 22.4614 19.688 22.4367C20.2907 21.7254 20.8293 20.9623 21.2977 20.1562C21.778 19.3212 22.1775 18.4422 22.4907 17.5312C22.5007 17.5002 22.5022 17.467 22.4951 17.4352C22.488 17.4034 22.4726 17.3741 22.4503 17.3503Z" fill="white"/>
                </svg>
                <p className='roboto text-white font-[500]'>+48222639051</p>
              </div>
              <div className='flex gap-[20px] items-center mt-[10px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M23.3429 4C23.7043 4 24 4.25201 24 4.56006V4.63094C24 4.93898 23.7555 5.33275 23.4567 5.50601L13.0376 12.1062C12.7387 12.2795 12.2497 12.2795 11.9508 12.1063L1.54345 5.51591C1.2446 5.34265 1 4.94899 1 4.64095V4.56017C1 4.25213 1.29569 4.00011 1.65711 4.00011H23.3429V4Z" fill="white"/>
                  <path d="M11.964 13.9613C12.2556 14.1433 12.7328 14.1434 13.0245 13.9615L23.4697 7.4468C23.7615 7.26488 24 7.36804 24 7.67609V19.4394C24 19.7475 23.7043 19.9995 23.3429 19.9995H1.65711C1.29569 19.9995 1 19.7475 1 19.4394V7.67609C1 7.36804 1.23853 7.26499 1.53012 7.44702L11.964 13.9613Z" fill="white"/>
                </svg>
                <p className='roboto text-white font-[500]'>support@{hostname}</p>
              </div>
            </div>
          </div>
          <div>
            <p className='max-w-[412px] roboto font-[500] text-[#FFFFFFB2] text-[16px] xl:text-[24px]'>Amsterdam, Brussels, Dublin, Lisbon, Milan, Oslo, Paris, Warsaw</p>
            <div className='flex flex-col xl:flex-row gap-[2px] xl:gap-[50px] mt-[20px] xl:mt-[50px]'>
              <div className='flex flex-col gap-[2px]'>
                <Link to="/about"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.2')}</p></Link>
                <Link to="/media"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.3')}</p></Link>
                <Link to="/regulation"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.4')}</p></Link>
              </div>
              <div className='flex flex-col gap-[2px] justify-end'>
                <a href="/dosc/cysec1.pdf"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.5')}</p></a>
                <a href="/docs/agreement.pdf"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.6')}</p></a>
              </div>
              <div className='flex flex-col gap-[2px] justify-end'>
                <a href="/docs/policy.pdf"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.7')}</p></a>
                <a href="/docs/interests.pdf"><p className='text-[#FFFFFFB2] text-[16px] xl:text-[20px] roboto'>{t('footer.8')}</p></a>
              </div>
            </div>    
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
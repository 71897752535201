import React from "react";

import { useTranslation, Trans } from "react-i18next";

import en1 from '../../img/Raise/en1.png'
import en2 from '../../img/Raise/en2.png'
import en3 from '../../img/Raise/en3.png'

function Markets () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative max-w-[1280px] xl:mx-auto mx-[20px] pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.27')}</p>
                <p className="xl:max-w-[517px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('raise.28')}</p>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px]">
                <div className="xl:w-[393px] h-[429px] bg-[#EBF6F5] px-[40px] pt-[40px] pb-[20px] flex flex-col justify-between">
                    <p className="roboto text-[20px] xl:text-[24px] text-[#00685E] font-[500]">{t('raise.29')}</p>
                    <img src={en1} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('raise.30')}</p>
                </div>
                <div className="xl:w-[393px] h-[429px] bg-[#EBF6F5] px-[40px] pt-[40px] pb-[20px] flex flex-col justify-between mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[24px] text-[#00685E] font-[500]">{t('raise.31')}</p>
                    <img src={en2} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('raise.32')}</p>
                </div>
                <div className="xl:w-[393px] h-[429px] bg-[#EBF6F5] px-[40px] pt-[40px] pb-[20px] flex flex-col justify-between mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[24px] text-[#00685E] font-[500]">{t('raise.33')}</p>
                    <img src={en3} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('raise.34')}</p>
                </div>
            </div>
        </div>
    );
}

export default Markets;
import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/Raise/image.png'

function Howtogopublic () {

    const { t } = useTranslation();
      
    return(
        <>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.35')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] mt-[20px] xl:mt-[40px] font-[500]">{t('raise.36')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.37')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.38')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.39')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] mt-[20px] xl:mt-[40px] font-[500]">{t('raise.40')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] xl:mt-[40px] font-[400]">{t('raise.41')}</p>
                </div>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.42')}</p>
                    <p className="xl:max-w-[600px] roboto font-[500] text-[#000000B2] text-[20px] xl:text-[24px] mt-[20px] xl:mt-[115px]">{t('raise.44')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.45')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.46')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.47')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.48')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.49')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.50')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.51')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.52')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.53')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] font-[400]">{t('raise.43')}</p>
                    <p className="xl:max-w-[600px] roboto font-[500] text-[#000000B2] text-[20px] xl:text-[24px] mt-[20px] xl:mt-[40px]">{t('raise.54')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] font-[400] mt-[20px] xl:mt-[40px]">{t('raise.55')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] font-[400] mt-[20px]">{t('raise.56')}</p>
                </div>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[564px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.57')}</p>
                    <p className="xl:max-w-[600px] roboto font-[500] text-[#000000B2] text-[20px] xl:text-[24px] mt-[20px] xl:mt-[40px]">{t('raise.58')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] font-[400] mt-[20px]">{t('raise.59')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={img} alt="" className="mx-auto"/>
                </div>
            </div>
        </>
    );
}

export default Howtogopublic;
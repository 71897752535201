import React from "react";

import { useTranslation } from "react-i18next";

import m1 from '../../img/Trading/m1.png'
import m2 from '../../img/Trading/m2.png'
import m3 from '../../img/Trading/m3.png'

function More () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto">
            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('trading.40')}</p>
            <div className="mt-[20px] xl:mt-[60px] flex flex-col xl:flex-row justify-between border-t-[1px] border-b-[1px] border-[#0000004D]">
                <div className="mt-[40px]">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.41')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px] max-w-[600px]">{t('trading.42')}</p>
                </div>
                <img src={m1} alt="" className="mt-[20px] xl:mt-0" />
            </div>
            <div className="mt-[20px] flex flex-col xl:flex-row-reverse justify-between border-t-[1px] border-b-[1px] border-[#0000004D]">
                <div className="mt-[40px]">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.43')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px] max-w-[600px]">{t('trading.44')}</p>
                </div>
                <img src={m2} alt="" className="mt-[20px] xl:mt-0"/>
            </div>
            <div className="mt-[20px] flex flex-col xl:flex-row justify-between border-t-[1px] border-b-[1px] border-[#0000004D]">
                <div className="mt-[40px]">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E]">{t('trading.45')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[20px] max-w-[600px]">{t('trading.46')}</p>
                </div>
                <img src={m3} alt="" className="mt-[20px] xl:mt-0" />
            </div>
        </div>
    );
}

export default More;
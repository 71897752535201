import React from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Button from '../Button'

function Regulation () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto pb-[80px] xl:pb-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('trading.47')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[40px] xl:max-w-[600px]">{t('trading.48')}</p>
                    <Link to="/regulation">
                        <div className="button_main flex cursor-pointer bg-[#00685E] mt-[40px]">
                            <p className="uppercase roboto font-[500] text-[16px] text-[#FFF]">{t('trading.49')}</p>
                        </div>
                    </Link>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('trading.50')}</p>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] mt-[40px] xl:max-w-[600px]">{t('trading.51')}</p>
                    <Button className="mt-[40px]" text={t('trading.52')} color="#00685E" textcolor="#FFF"/>
                </div>
            </div>    
        </div>
    );
}

export default Regulation;
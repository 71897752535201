import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Regulation/main.png'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative h-[662px]">
            <img src={main} alt="" className="w-full h-full object-cover"/>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="absolute top-[100px]">
                    <p className="text-white font-[500] text-[32px] xl:text-[64px] leading-[1.2] max-w-[781px]"><Trans>{t('regulation.1')}</Trans></p>                   
                    <p className="text-[#FFFFFFB2] xl:max-w-[700px] mt-[20px] xl:mt-[40px] roboto text-[14px] xl:text-[16px]">{t('regulation.2')}</p>
                    <p className="text-[#FFFFFFB2] xl:max-w-[700px] mt-[20px] roboto text-[14px] xl:text-[16px]">{t('regulation.3')}</p>
                    <p className="text-[#FFFFFFB2] xl:max-w-[700px] mt-[20px] roboto text-[14px] xl:text-[16px]">{t('regulation.4')}</p>
                </div>
            </div>
        </div>
    );
}

export default Main;
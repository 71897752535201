import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/About/img.png'

function Strategy () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col-reverse xl:flex-row justify-between">
                <div className="mt-[20px] xl:mt-0">
                    <img src={img} alt="" />
                </div>
                <div className="xl:w-[600px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.52')}</p>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.53')}</p>
                    <p className="mt-[20px] xl:mt-[60px] roboto text-[16px] xl:text-[24px] font-[500] text-[#000000B2]">{t('about.54')}</p>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.55')}</p>
                    <p className="mt-[20px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.56')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.57')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.58')}</p>
                </div>
            </div>
            <div className="flex flex-col-reverse xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[539px]">{t('about.59')}</p>
                </div>
                <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[24px] text-[#000000B2] font-[500]">{t('about.60')}</p>
                    <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.61')}</p>
                </div>
            </div>
        </div>
    );
}

export default Strategy;
import React, { useEffect } from "react";

import Main from '../components/About/Main'
import Business from "../components/About/Business";
import Leading from "../components/About/Leading";
import Presence from "../components/About/Presence";
import Strategy from "../components/About/Strategy";
import TenYears from "../components/About/TenYears";
import OurHistory from "../components/About/OurHistory";
import Making from "../components/About/Making";

function About () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Business/>
            <Leading/>
            <Presence/>
            <Strategy/>
            <TenYears/>
            <OurHistory/>
            <Making/>
        </div>
    );
}

export default About;
import React, { useEffect } from "react";

import Main from '../components/Listing/Main'
import Switcher from "../components/Listing/Switcher";

function Listing () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Switcher/>
        </div>
    );
}

export default Listing;
import React, { useState } from "react";

import { useLocation } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import { useTranslation, Trans } from "react-i18next";

import arrow from '../../img/Raise/arrow.png'

import sw1 from '../../img/Listing/sw1.png'
import sw2 from '../../img/Listing/sw2.png'
import sw3 from '../../img/Listing/sw3.png'
import sw4 from '../../img/Listing/sw4.png'
import sw5 from '../../img/Listing/sw5.png'
import sw6 from '../../img/Listing/sw6.png'
import sw7 from '../../img/Listing/sw7.png'

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import e1 from '../../img/Listing/e1.png'
import e2 from '../../img/Listing/e2.png'
import e3 from '../../img/Listing/e3.png'

import sw51 from '../../img/Listing/sw51.png'

import tr1 from '../../img/Listing/trusted/tr1.png'
import tr2 from '../../img/Listing/trusted/tr2.png'
import tr3 from '../../img/Listing/trusted/tr3.png'
import tr4 from '../../img/Listing/trusted/tr4.png'
import tr5 from '../../img/Listing/trusted/tr5.png'
import tr6 from '../../img/Listing/trusted/tr6.png'
import tr7 from '../../img/Listing/trusted/tr7.png'
import tr8 from '../../img/Listing/trusted/tr8.png'
import tr9 from '../../img/Listing/trusted/tr9.png'
import tr10 from '../../img/Listing/trusted/tr10.png'
import tr11 from '../../img/Listing/trusted/tr11.png'
import tr12 from '../../img/Listing/trusted/tr12.png'
import tr13 from '../../img/Listing/trusted/tr13.png'

import imgsl1 from '../../img/Listing/sl/sl1.png'
import imgsl2 from '../../img/Listing/sl/sl2.png'
import imgsl3 from '../../img/Listing/sl/sl3.png'
import imgsl4 from '../../img/Listing/sl/sl4.png'

const data = [
    {
        index: 0,
        title: 'listing.5',
    },
    {
        index: 1,
        title: 'listing.6',
    },
    {
        index: 2,
        title: 'listing.7',
    },
    {
        index: 3,
        title: 'listing.8',
    },
    {
        index: 4,
        title: 'listing.9',
    },
    {
        index: 5,
        title: 'listing.10',
    },
    {
        index: 6,
        title: 'listing.11',
    },
]

const faqData1 = [
    {
        question: 'listing.24',
        answer: 'listing.25'
    },
    {
        question: 'listing.26',
        answer: 'listing.27'
    },
    {
        question: 'listing.28',
        answer: 'listing.29'
    }
]

const faqData2 = [
    {
        question: 'listing.30',
        answer: 'listing.31'
    },
    {
        question: 'listing.32',
        answer: 'listing.33'
    }
]

const faqData3 = [
    {
        question: 'listing.124',
        answer: 'listing.125'
    },
    {
        question: 'listing.126',
        answer: 'listing.127'
    },
    {
        question: 'listing.128',
        answer: 'listing.129'
    },
]


function Switcher () {

    const { t } = useTranslation();

    const location = useLocation();

    const { active: initialActive } = location.state || { active: 0 };

    const [active, setActive] = useState(initialActive);

    const [openIndex1, setOpenIndex1] = useState(null);

    const toggleText1 = (index1) => {
        setOpenIndex1((prevIndex1) => (prevIndex1 === index1 ? null : index1));
    };

    const [openIndex2, setOpenIndex2] = useState(null);

    const toggleText2 = (index2) => {
        setOpenIndex2((prevIndex2) => (prevIndex2 === index2 ? null : index2));
    };

    const [openIndex3, setOpenIndex3] = useState(null);

    const toggleText3 = (index3) => {
        setOpenIndex3((prevIndex3) => (prevIndex3 === index3 ? null : index3));
    };
    
    const images = [tr1, tr2, tr3, tr4, tr5, tr6, tr7, tr8, tr9, tr10, tr11, tr12, tr13];

    const [swiper, setSwiper] = useState({});

    return(
        <div className="overflow-hidden relative">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] pt-[40px] xl:pt-[80px]">
                <div className="flex flex-wrap justify-between gap-[10px] pb-[40px] xl:pb-[60px]">
                    {data.map((dat) => (
                        <div key={dat.index} className={`flex-grow py-[15px] transition-all cursor-pointer ${active === dat.index ? 'bg-[#00685E]' : ' bg-[#EBF6F5]'}`} onClick={() => setActive(dat.index)}>
                            <p className={`transition-all text-[16px] leading-[1.2] xl:text-[24px] roboto text-center ${active === dat.index ? 'text-[#FFFFFF]' : ' text-[#00685E]'}`}>{t(dat.title)}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {active === 0 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                        <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.12')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('listing.13')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.14')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] font-[500] mt-[20px] italic xl:mt-[40px]">{t('listing.15')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw1} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                        <div className="bg-[#EBF6F5] mt-[60px] xl:mt-[120px] py-[60px]">
                            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                                <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">{t('listing.17')}</p>
                                <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4 mt-[20px] xl:mt-[60px]">
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">6.600 €</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.18')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">18 countries</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.19')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">#1 Exchange</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.20')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">1,500+</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.21')}</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[50px] xl:pt-[100px]">
                            <div className="xl:w-[600px]">
                                <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2]">{t('listing.22')}</p>
                                <div className="flex flex-col mt-[30px]">
                                    {faqData1.map((faq, index1) => (
                                        <div key={index1}>
                                            <div onClick={() => toggleText1(index1)} className={`flex justify-between px-[40px] mt-[10px] transition-all duration-300 bg-[#00685E] cursor-pointer ${openIndex1 === index1 ? 'h-auto' : 'h-[70px]'}`}>
                                                <div>
                                                    <p className='font-[500] mt-[25px] xl:mt-[17px] roboto text-[16px] xl:text-[24px] text-[#fff] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                                    {openIndex1 === index1 && (
                                                        <p className='text-[#FFFFFF90] open-sans text-[16px] xl:text-[20px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[500px]'>{t(faq.answer)}</p>
                                                    )}
                                                </div>
                                                <div className='cursor-pointer mt-[27px]'>
                                                    <img src={arrow} alt="" className={`${openIndex1 === index1 ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                                <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2]">{t('listing.23')}</p>
                                <div className="flex flex-col mt-[30px]">
                                    {faqData2.map((faq, index2) => (
                                        <div key={index2}>
                                            <div onClick={() => toggleText2(index2)} className={`flex justify-between px-[40px] mt-[10px] transition-all duration-300 bg-[#00685E] cursor-pointer ${openIndex2 === index2 ? 'h-auto' : 'h-[70px]'}`}>
                                                <div>
                                                    <p className='font-[500] mt-[25px] xl:mt-[17px] roboto text-[16px] xl:text-[24px] text-[#fff] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                                    {openIndex2 === index2 && (
                                                        <p className='text-[#FFFFFF90] open-sans text-[16px] xl:text-[20px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[500px]'>{t(faq.answer)}</p>
                                                    )}
                                                </div>
                                                <div className='cursor-pointer mt-[27px]'>
                                                    <img src={arrow} alt="" className={`${openIndex2 === index2 ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                            <div>
                                <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.34')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="xl:max-w-[517px] roboto font-[400] text-[#000000B2] text-[16px] xl:text-[20px]">{t('listing.35')}</p>
                            </div>
                        </div>
                        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col gap-y-[20px] xl:flex-row justify-between pt-[20px] xl:pt-[40px]">
                            <div className="xl:w-[393px] h-[429px] bg-[#EBF6F5] px-[40px] py-[20px] flex flex-col justify-between">
                                <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('listing.36')}</p>
                                <img src={e1} alt="" /> 
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('listing.37')}</p>
                            </div>
                            <div className="xl:w-[393px] h-[429px] bg-[#EBF6F5] px-[40px] py-[20px] flex flex-col justify-between">
                                <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('listing.38')}</p> 
                                <img src={e2} alt="" /> 
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('listing.39')}</p>
                            </div>
                            <div className="xl:w-[393px] h-[429px] bg-[#EBF6F5] px-[40px] py-[20px] flex flex-col justify-between">
                                <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('listing.40')}</p>
                                <img src={e3} alt="" />  
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('listing.41')}</p>
                            </div>
                        </div>
                    </div>
                : null}

                {active === 1 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                        <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.42')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('listing.43')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.44')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw2} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                        <div className="bg-[#EBF6F5] mt-[60px] xl:mt-[120px] py-[60px]">
                            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                                <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4">
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">10,000+</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.45')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">3,700+</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.46')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">56+</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.47')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">3,200+</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.48')}</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[60px] xl:pt-[120px]">
                            <div>
                                <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.49')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] xl:mt-[40px] font-[400]">{t('listing.50')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.51')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.52')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] xl:mt-[40px] font-[400]">{t('listing.53')}</p>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px]">
                            <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.54')}</p>
                            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px] gap-[10px]">
                                <div className="xl:w-[600px]">
                                    <div className="bg-[#00685E] py-[20px] flex flex-col gap-[10px] px-[20px] xl:px-[40px]">
                                        <p className="roboto text-white font-[500] text-[20px] xl:text-[24px]">{t('listing.55')}</p>
                                        <p className="roboto text-[#FFFFFFB2] font-[400] text-[16px] xl:text-[20px]">{t('listing.56')}</p>
                                    </div>
                                    <div className="bg-[#00685E] py-[20px] flex flex-col gap-[10px] px-[20px] xl:px-[40px] mt-[10px]">
                                        <p className="roboto text-white font-[500] text-[20px] xl:text-[24px]">{t('listing.57')}</p>
                                        <p className="roboto text-[#FFFFFFB2] font-[400] text-[16px] xl:text-[20px]">{t('listing.58')}</p>
                                    </div>
                                </div>
                                <div className="xl:w-[600px]">
                                    <div className="bg-[#00685E] py-[20px] flex flex-col gap-[10px] px-[20px] xl:px-[40px]">
                                        <p className="roboto text-white font-[500] text-[20px] xl:text-[24px]">{t('listing.59')}</p>
                                        <p className="roboto text-[#FFFFFFB2] font-[400] text-[16px] xl:text-[20px]">{t('listing.60')}</p>
                                    </div>
                                    <div className="bg-[#00685E] py-[20px] flex flex-col gap-[10px] px-[20px] xl:px-[40px] mt-[10px]">
                                        <p className="roboto text-white font-[500] text-[20px] xl:text-[24px]">{t('listing.61')}</p>
                                        <p className="roboto text-[#FFFFFFB2] font-[400] text-[16px] xl:text-[20px]">{t('listing.62')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between mt-[60px] xl:mt-[120px]">
                            <div>
                                <p className="xl:max-w-[517px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.63')}</p>
                                <p className="xl:max-w-[359px] text-[#000000B2] text-[16px] xl:text-[24px] mt-[20px]">{t('listing.64')}</p>
                            </div>
                            <div>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.65')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.66')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.67')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.68')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.69')}</p>
                            </div>
                            <div>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.70')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.71')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.72')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.73')}</p>
                            </div>
                        </div>
                        <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[60px] xl:mt-[120px]">
                            <p className="xl:max-w-[517px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.74')}</p>
                            <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4 mt-[20px] xl:mt-[60px] items-center">
                                {images.map((img, index) => (
                                    <div key={index}>
                                        <img src={img} alt="" className="mx-auto"/>
                                    </div>
                                ))}
                            </div>
                        </div> 
                    </div>
                : null}
                {active === 2 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                        <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div className="xl:w-[600px] flex flex-col justify-between">
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.75')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('listing.76')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.77')}</p>
                                <div className="bg-[#EBF6F5] py-[20px] flex flex-col xl:flex-row justify-center gap-[20px] xl:gap-[100px] mt-[20px] xl:mt-0">
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">+3,100</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.78')}</Trans></p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">+45</p>
                                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('listing.79')}</Trans></p>
                                    </div>
                                </div> 
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw3} alt="" className="mx-auto"/></Fade>
                            </div>              
                        </div>
                        <div className=" max-w-[1280px] xl:mx-auto mx-[20px]">
                            <Button className="mt-[20px] xl:mt-[40px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                        </div>
                        <div className=" max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between mt-[60px] xl:mt-[120px]">
                            <div className="flex flex-col justify-between">
                                <div>
                                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.80')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.81')}</p>
                                </div>
                                <div className="flex gap-[20px] mt-[20px] xl:mt-0">
                                    <div className="w-[34px] h-[34px] xl:w-[54px] xl:h-[54px] border-[2px] border-[#00685E] cursor-pointer hover:bg-[#00685E] transition-all flex items-center justify-center hover-fill" onClick={() => swiper.slidePrev()}>
                                        <svg className="scale-75 xl:scale-100" width="29" height="14" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.863602 6.3636C0.512131 6.71507 0.512131 7.28492 0.863602 7.63639L6.59117 13.364C6.94264 13.7154 7.51249 13.7154 7.86396 13.364C8.21543 13.0125 8.21543 12.4426 7.86396 12.0912L2.77279 7L7.86396 1.90883C8.21543 1.55736 8.21543 0.987509 7.86396 0.636037C7.51249 0.284566 6.94264 0.284565 6.59117 0.636037L0.863602 6.3636ZM28.5 6.1L1.5 6.1L1.5 7.9L28.5 7.9L28.5 6.1Z" fill="#00685E"/>
                                        </svg>
                                    </div>
                                    <div className="w-[34px] h-[34px] xl:w-[54px] xl:h-[54px] border-[2px] border-[#00685E] cursor-pointer hover:bg-[#00685E] transition-all flex items-center justify-center hover-fill" onClick={() => swiper.slideNext()}>
                                        <svg width="29" height="14" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180 scale-75 xl:scale-100">
                                            <path d="M0.863602 6.3636C0.512131 6.71507 0.512131 7.28492 0.863602 7.63639L6.59117 13.364C6.94264 13.7154 7.51249 13.7154 7.86396 13.364C8.21543 13.0125 8.21543 12.4426 7.86396 12.0912L2.77279 7L7.86396 1.90883C8.21543 1.55736 8.21543 0.987509 7.86396 0.636037C7.51249 0.284566 6.94264 0.284565 6.59117 0.636037L0.863602 6.3636ZM28.5 6.1L1.5 6.1L1.5 7.9L28.5 7.9L28.5 6.1Z" fill="#00685E"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                                <Swiper
                                    loop={true}
                                    onInit={(e) => {
                                        setSwiper(e);
                                    }}
                                    spaceBetween={10}
                                    className="w-full xl:w-[600px]"
                                >
                                    <SwiperSlide>
                                        <div className="h-[400px] xl:w-[600px] bg-[#00685E] p-[40px]">
                                            <p className="text-[20px] xl:text-[24px] roboto text-white font-[500]">{t('listing.82')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[16px] xl:text-[20px] mt-[20px]">• {t('listing.83')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[16px] xl:text-[20px] mt-[12px]">• {t('listing.84')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[16px] xl:text-[20px] mt-[12px]">• {t('listing.85')}</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="h-[400px] xl:w-[600px] bg-[#00685E] p-[40px]">
                                            <p className="text-[20px] xl:text-[24px] roboto text-white font-[500]">{t('listing.86')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[14px] xl:text-[16px] mt-[20px]">• {t('listing.87')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[14px] xl:text-[16px] mt-[12px]">• {t('listing.88')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[14px] xl:text-[16px] mt-[12px]">• {t('listing.89')}</p>
                                            <p className="roboto text-[#FFF] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.90')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[14px] xl:text-[16px] mt-[12px]">• {t('listing.91')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[14px] xl:text-[16px] mt-[12px]">• {t('listing.92')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[14px] xl:text-[16px] mt-[12px]">• {t('listing.93')}</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="h-[400px] xl:w-[600px] bg-[#00685E] p-[40px]">
                                            <p className="text-[20px] xl:text-[24px] roboto text-white font-[500]">{t('listing.94')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[16px] xl:text-[20px] mt-[20px]">• {t('listing.95')}</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="h-[400px] xl:w-[600px] bg-[#00685E] p-[40px]">
                                            <p className="text-[20px] xl:text-[24px] roboto text-white font-[500]">{t('listing.96')}</p>
                                            <p className="roboto text-[#FFFFFFB2] text-[16px] xl:text-[20px] mt-[20px]">• {t('listing.97')}</p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px]">
                            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.98')}</p>
                            <div className=" flex flex-col xl:flex-row justify-between">
                                <div>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('listing.99')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.100')}</p>
                                </div>
                                <div className="mt-[20px] xl:mt-0">
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('listing.101')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.102')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('listing.103')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px]">
                            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.104')}</p>
                            <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] xl:mt-[40px] font-[400]">{t('listing.105')}</p>
                        </div>
                    </div>
                : null}
                {active === 3 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                       <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.106')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px]">{t('listing.107')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.108')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.109')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.110')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.111')}</p>
                                <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.112')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw4} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">
                            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.113')}</p>
                            <div className="flex flex-wrap justify-between mt-[20px] xl:mt-[40px] gap-[20px]">
                                <div className="xl:w-[290px] flex-grow h-[295px] relative">
                                    <img src={imgsl1} alt="" className="w-full h-full object-cover"/>
                                    <div className="absolute blur-swiper left-[10px] bottom-[10px] px-[10px] py-[2px] flex items-center justify-center">
                                        <p className="roboto text-white text-[16px] xl:text-[24px]">{t('listing.114')}</p>
                                    </div>
                                </div>
                                <div className="xl:w-[290px] flex-grow h-[295px] relative">
                                    <img src={imgsl2} alt="" className="w-full h-full object-cover"/>
                                    <div className="absolute blur-swiper left-[10px] bottom-[10px] px-[10px] py-[2px] flex items-center justify-center">
                                        <p className="roboto text-white text-[16px] xl:text-[24px]">{t('listing.115')}</p>
                                    </div>
                                </div>
                                <div className="xl:w-[290px] flex-grow h-[295px] relative">
                                    <img src={imgsl3} alt="" className="w-full h-full object-cover"/>
                                    <div className="absolute blur-swiper left-[10px] bottom-[10px] px-[10px] py-[2px] flex items-center justify-center">
                                        <p className="roboto text-white text-[16px] xl:text-[24px]">{t('listing.116')}</p>
                                    </div>
                                </div>
                                <div className="xl:w-[290px] flex-grow h-[295px] relative">
                                    <img src={imgsl4} alt="" className="w-full h-full object-cover"/>
                                    <div className="absolute blur-swiper left-[10px] bottom-[10px] px-[10px] py-[2px] flex items-center justify-center">
                                        <p className="roboto text-white text-[16px] xl:text-[24px]">{t('listing.117')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
                {active === 4 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                         <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.118')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px] xl:mt-[40px]">{t('listing.119')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]">{t('listing.120')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw5} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px]">
                            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.121')}</p>
                            <div className="flex flex-col xl:flex-row justify-between">
                                <div>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.122')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.123')}</p>
                                </div>
                                <div className="mt-[20px] xl:mt-0 xl:w-[600px]">
                                    <div className="flex flex-col">
                                        {faqData3.map((faq, index3) => (
                                            <div key={index3}>
                                                <div onClick={() => toggleText3(index3)} className={`flex justify-between px-[40px] mt-[10px] transition-all duration-300 bg-[#00685E] cursor-pointer ${openIndex3 === index3 ? 'h-auto' : 'h-[70px]'}`}>
                                                    <div>
                                                        <p className='font-[500] mt-[25px] xl:mt-[17px] roboto text-[16px] xl:text-[24px] text-[#fff] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                                        {openIndex3 === index3 && (
                                                            <p className='text-[#FFFFFF90] open-sans text-[16px] xl:text-[20px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[500px]'>{t(faq.answer)}</p>
                                                        )}
                                                    </div>
                                                    <div className='cursor-pointer mt-[27px]'>
                                                        <img src={arrow} alt="" className={`${openIndex3 === index3 ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px] flex flex-col xl:flex-row justify-between">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.130')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.131')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.132')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.133')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[10px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.134')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.135')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.136')}</p>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px]">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.137')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.138')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]">{t('listing.139')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]">{t('listing.140')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw51} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                    </div>
                : null}
                {active === 5 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                        <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div className="flex flex-col justify-between">
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.141')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px] xl:mt-0">{t('listing.142')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw6} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px] flex flex-col xl:flex-row justify-between">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.143')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.144')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.145')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.146')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[10px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.147')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.148')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.149')}</p>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between mt-[60px] xl:mt-[120px] gap-[20px]">
                            <div className="w-[393px] h-[429px] bg-[#EBF6F5] p-[40px]"> 
                                <p className="text-[16px] xl:text-[24px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.150')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.151')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.152')}</p>
                            </div>
                            <div className="w-[393px] h-[429px] bg-[#EBF6F5] p-[40px]"> 
                                <p className="text-[16px] xl:text-[24px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.153')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.154')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.155')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.156')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.157')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.158')}</p>
                            </div>
                            <div className="w-[393px] h-[429px] bg-[#EBF6F5] p-[40px]"> 
                                <p className="text-[16px] xl:text-[24px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.159')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.160')}</p>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px] flex flex-col xl:flex-row justify-between">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.161')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.162')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[10px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.163')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.164')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.165')}</p>
                            </div>
                        </div>
                    </div>
                : null}
                {active === 6 ? 
                    <div className="pb-[80px] xl:pb-[160px]">
                        <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] xl:mx-auto mx-[20px]">
                            <div className="flex flex-col justify-between">
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('listing.166')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500] mt-[20px] xl:mt-0">{t('listing.167')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px] xl:mt-0">{t('listing.168')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px] xl:mt-0">{t('listing.169')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px] xl:mt-0">{t('listing.170')}</p>
                                <Button className="mt-[40px] xl:mt-[60px]" text={t('listing.16')} color="#00685E" textcolor="#FFF"/>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <Fade right><img src={sw7} alt="" className="mx-auto"/></Fade>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px] flex flex-col xl:flex-row justify-between">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.171')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.172')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[10px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.173')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.174')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.175')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.176')}</p>
                            </div>
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px]">
                            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.177')}</p>
                            <div className="flex flex-col xl:flex-row justify-between gap-[20px] mt-[20px] xl:mt-[40px]">
                                <div className="w-[393px] h-[429px] bg-[#EBF6F5] p-[40px]"> 
                                    <p className="text-[16px] xl:text-[24px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.178')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.179')}</p>
                                </div>
                                <div className="w-[393px] h-[429px] bg-[#EBF6F5] p-[40px]"> 
                                    <p className="text-[16px] xl:text-[24px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.180')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.181')}</p>
                                </div>
                                <div className="w-[393px] h-[429px] bg-[#EBF6F5] p-[40px]"> 
                                    <p className="text-[16px] xl:text-[24px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.182')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.183')}</p>
                                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[16px] mt-[20px]">{t('listing.184')}</p>
                                </div>
                            </div>                          
                        </div>
                        <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[60px] xl:mt-[120px] flex flex-col xl:flex-row justify-between">
                            <div>
                                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[600px]">{t('listing.185')}</p>
                                <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px]">{t('listing.186')}</p>
                            </div>
                            <div className="mt-[20px] xl:mt-0">
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[10px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.187')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.188')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('listing.189')}</p>
                                <p className="xl:w-[500px] text-[#000000B2] text-[16px] xl:text-[20px] mt-[6px]">{t('listing.190')}</p>
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    );
}

export default Switcher;
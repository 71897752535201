import React from "react";

import { useTranslation } from "react-i18next";

import image2 from '../../img/Media/image2.png'

function Building () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px] pb-[80px] xl:pb-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[600px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('media.18')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px] xl:mt-[40px]">{t('media.19')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={image2} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Building;
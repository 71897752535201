import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import i1 from '../../img/Raise/i1.png'
import i2 from '../../img/Raise/i2.png'

import Fade from 'react-reveal/Fade'

function Equity () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto">
           <div className="flex flex-col-reverse xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="mt-[20px] xl:mt-0">
                    <Fade left><img src={i1} alt="" className="mx-auto"/></Fade>
                </div>
                <div className="flex flex-col gap-[20px] xl:gap-[40px] max-w-[600px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.7')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[20px]">{t('raise.8')}</p>
                </div>
           </div>
           <div className="flex flex-col-reverse xl:flex-row justify-between">
                <div className="mt-[20px] xl:mt-[50px] xl:ml-[100px]">
                    <Fade left><img src={i2} alt="" className="mx-auto"/></Fade>
                </div>
                <div className="flex flex-col xl:w-[600px] mt-[20px] xl:mt-0">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.9')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[40px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.10')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.11')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.12')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.14')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.15')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]">{t('raise.16')}</p>
                </div>
           </div>
        </div>
    );
}

export default Equity;
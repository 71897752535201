import React from "react";

import { useTranslation } from "react-i18next";

function Support () {

    const { t } = useTranslation();
      
    return(
        <div className="mt-[80px] xl:mt-[160px] pb-[80px] xl:pb-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="xl:max-w-[462px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.109')}</p> 
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[16px] xl:text-[24px] font-[500]">{t('raise.110')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.111')}</p>
                </div>
            </div>
        </div>
    );
}

export default Support;
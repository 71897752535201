import React from "react";

import { useTranslation } from "react-i18next";

function Trading () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto">
            <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('investing.4')}</p>
            <p className="text-[#000000B2] roboto text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px] xl:max-w-[600px]">{t('investing.5')}</p>
            <div className="flex flex-wrap justify-between pt-[20px] xl:pt-[60px] gap-[20px]">
                <div className="w-[393px] flex-grow h-[350px] xl:h-[459px] bg-[#00685E] px-[20px] py-[20px] xl:py-[40px]">
                    <p className="roboto text-white text-[20px] xl:text-[24px] font-[500]">{t('investing.6')}</p>
                    <p className="mt-[20px] xl:mt-[40px] text-[16px] xl:text-[20px] font-[500] text-[#FFFFFFB2]">{t('investing.7')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.8')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.9')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.10')}</p>
                </div>
                <div className="w-[393px] flex-grow h-[350px] xl:h-[459px] bg-[#00685E] px-[20px] py-[20px] xl:py-[40px]">
                    <p className="roboto text-white text-[20px] xl:text-[24px] font-[500]">{t('investing.11')}</p>
                    <p className="mt-[20px] xl:mt-[40px] text-[16px] xl:text-[20px] font-[500] text-[#FFFFFFB2]">{t('investing.12')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.13')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.14')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.15')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.16')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.17')}</p>
                </div>
                <div className="w-[393px] flex-grow h-[350px] xl:h-[459px] bg-[#00685E] px-[20px] py-[20px] xl:py-[40px]">
                    <p className="roboto text-white text-[20px] xl:text-[24px] font-[500]">{t('investing.18')}</p>
                    <p className="mt-[20px] xl:mt-[40px] text-[16px] xl:text-[20px] font-[500] text-[#FFFFFFB2]">{t('investing.19')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.20')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.21')}</p>
                    <p className="mt-[10px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2]"><span className="mr-[15px]">●</span>{t('investing.22')}</p>
                </div>
            </div>
        </div>
    );
}

export default Trading;
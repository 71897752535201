import React, { useState } from "react";

import { Link } from "react-router-dom";

import { useTranslation, Trans } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import Button from '../Button'

import img from '../../img/Home/img.png'
import soil from '../../img/Home/soiltech.png'

import sw1 from '../../img/Home/swiper/sw1.png'
import sw2 from '../../img/Home/swiper/sw2.png'
import sw3 from '../../img/Home/swiper/sw3.png'
import sw4 from '../../img/Home/swiper/sw4.png'

const slides = [
    {
        img: sw1,
        text: 'Amsterdam'
    },
    {
        img: sw2,
        text: 'Brussels'
    },
    {
        img: sw3,
        text: 'Dublin'
    },
    {
        img: sw4,
        text: 'Lisbon'
    },
]

function Unique () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});
      
    return(
        <div className="overflow-hidden relative bg-[#EBF6F5] pb-[20px] xl:pb-[80px]">
            <div className="pt-[40px] xl:pt-[80px] max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row">
                <div className="xl:w-[600px]">
                    <p className="xl:max-w-[600px] roboto text-[20px] xl:text-[40px] text-black leading-[1.2]">{t('home.9')}</p>
                    <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2] xl:max-w-[517px] mt-[20px] xl:mt-[40px]">{t('home.10')}</p>
                    <Link to="/about">
                        <div className="button_main flex cursor-pointer bg-[#00685E] mt-[60px]">
                            <p className="uppercase roboto font-[500] text-[16px] text-[#FFF]">{t('home.11')}</p>
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col justify-between mt-[40px] xl:mt-0">
                <Swiper
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    spaceBetween={10}
                    className="w-full xl:w-[600px]"
                    breakpoints={{
                        640: { // Screen width >= 640px (tablet)
                            slidesPerView: 2,
                        },
                        0: { // Screen width < 640px (mobile)
                            slidesPerView: 1,
                        },
                    }}
                >
                        {slides.map((sl, index) => (
                            <SwiperSlide key={index}>
                                <div className="w-full h-[295px]">
                                    <img src={sl.img} alt="" className="w-full h-full object-cover"/>
                                    <div className="absolute blur-swiper left-[10px] bottom-[10px] px-[10px] py-[2px] flex items-center justify-center">
                                        <p className="roboto text-white text-[16px] xl:text-[24px]">{sl.text}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="flex gap-[20px] mt-[20px]">
                        <div className="w-[34px] h-[34px] xl:w-[54px] xl:h-[54px] border-[2px] border-[#00685E] cursor-pointer hover:bg-[#00685E] transition-all flex items-center justify-center hover-fill" onClick={() => swiper.slidePrev()}>
                            <svg className="scale-75 xl:scale-100" width="29" height="14" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.863602 6.3636C0.512131 6.71507 0.512131 7.28492 0.863602 7.63639L6.59117 13.364C6.94264 13.7154 7.51249 13.7154 7.86396 13.364C8.21543 13.0125 8.21543 12.4426 7.86396 12.0912L2.77279 7L7.86396 1.90883C8.21543 1.55736 8.21543 0.987509 7.86396 0.636037C7.51249 0.284566 6.94264 0.284565 6.59117 0.636037L0.863602 6.3636ZM28.5 6.1L1.5 6.1L1.5 7.9L28.5 7.9L28.5 6.1Z" fill="#00685E"/>
                            </svg>
                        </div>
                        <div className="w-[34px] h-[34px] xl:w-[54px] xl:h-[54px] border-[2px] border-[#00685E] cursor-pointer hover:bg-[#00685E] transition-all flex items-center justify-center hover-fill" onClick={() => swiper.slideNext()}>
                            <svg width="29" height="14" viewBox="0 0 29 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180 scale-75 xl:scale-100">
                                <path d="M0.863602 6.3636C0.512131 6.71507 0.512131 7.28492 0.863602 7.63639L6.59117 13.364C6.94264 13.7154 7.51249 13.7154 7.86396 13.364C8.21543 13.0125 8.21543 12.4426 7.86396 12.0912L2.77279 7L7.86396 1.90883C8.21543 1.55736 8.21543 0.987509 7.86396 0.636037C7.51249 0.284566 6.94264 0.284565 6.59117 0.636037L0.863602 6.3636ZM28.5 6.1L1.5 6.1L1.5 7.9L28.5 7.9L28.5 6.1Z" fill="#00685E"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px]">
                <div className="flex flex-col justify-between">
                    <p className="max-w-[600px] roboto text-[20px] xl:text-[40px] text-black leading-[1.2]">{t('home.12')}</p>
                    <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2] max-w-[517px] mt-[20px]">{t('home.13')}</p>
                </div>
                <div className="xl:w-[600px] h-[350px] bg-[#00685E] relative mt-[20px] xl:mt-0">
                    <img src={img} alt="" className="absolute right-0"/>
                    <div className="px-[10px] xl:px-[80px] pt-[20px]">
                        <img src={soil} alt="" />
                        <div className="flex flex-col mt-[20px] gap-[4px]">
                            <p className="roboto text-[16px] xl:text-[20px] text-[#FFFFFFB2] spansol"><Trans>{t('home.14')}</Trans></p>
                            <p className="roboto text-[16px] xl:text-[20px] text-[#FFFFFFB2] spansol"><Trans>{t('home.15')}</Trans></p>
                            <p className="roboto text-[16px] xl:text-[20px] text-[#FFFFFFB2] spansol"><Trans>{t('home.16')}</Trans></p>
                            <p className="roboto text-[16px] xl:text-[20px] text-[#FFFFFFB2] spansol"><Trans>{t('home.17')}</Trans></p>
                            <p className="roboto text-[16px] xl:text-[20px] text-[#FFFFFFB2] spansol"><Trans>{t('home.18')}</Trans></p>
                            <p className="roboto text-[16px] xl:text-[20px] text-[#FFFFFFB2] spansol"><Trans>{t('home.19')}</Trans></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Unique;
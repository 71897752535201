import React from "react";

import { useTranslation } from "react-i18next";

function Button ( { className, text, color, textcolor } ) {

    const { t } = useTranslation();
      
    return(
        <div className={className}>
            <a href="https://user.enxb.org/">
                <div className="button_main flex cursor-pointer" style={{ backgroundColor: color }}>
                    <p className="uppercase roboto font-[500] text-[16px]" style={{ color: textcolor}}>{text}</p>
                </div>
            </a>
        </div>
    );
}

export default Button;
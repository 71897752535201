import React, { useEffect } from "react";

import Main from '../components/Media/Main'
import Contacts from "../components/Media/Contacts";
import Commitment from "../components/Media/Commitment";
import Building from "../components/Media/Building";

function Media () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Contacts/>
            <Commitment/>
            <Building/>
        </div>
    );
}

export default Media;
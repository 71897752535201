import React, { useEffect } from "react";

import Main from '../components/Investing/Main'
import Comprehensive from "../components/Investing/Comprehensive";
import Trading from "../components/Investing/Trading";
import Safe from "../components/Investing/Safe";
import Brokers from "../components/Investing/Brokers";

function Investing () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Comprehensive/>
            <Trading/>
            <Safe/>
            <Brokers/>
        </div>
    );
}

export default Investing;
import React from "react";

import { useTranslation, Trans } from "react-i18next";

function Enxbmarkets () {

    const { t } = useTranslation();
      
    return(
        <div className="bg-[#EBF6F5] mt-[80px] xl:mt-[160px] py-[60px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">{t('raise.104')}</p>
                <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4 mt-[20px] xl:mt-[60px]">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">56,000+</p>
                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center">{t('raise.105')}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">€3.2Tn</p>
                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('raise.106')}</Trans></p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">2,400+</p>
                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center">{t('raise.107')}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[40px] leading-[1.2] mx-auto text-center">€295Bn+</p>
                        <p className="text-[#000000B2] font-[500] text-[16px] xl:text-[20px] roboto mt-[10px] text-center"><Trans>{t('raise.108')}</Trans></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Enxbmarkets;
import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import OurPriorities from "../components/Home/OurPriorities";
import Unique from "../components/Home/Unique";
import List from "../components/Home/List";
import Raise from "../components/Home/Raise";
import AccountTypes from "../components/Home/AccountTypes";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <OurPriorities/>
            <Unique/>
            <List/>
            <Raise/>
            <AccountTypes/>
        </div>
    );
}

export default Home;
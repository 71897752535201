import React from "react";

import { useTranslation } from "react-i18next";

import image2 from '../../img/About/image2.png'

function Making () {

    const { t } = useTranslation();
      
    return(
        <div className="relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.81')}</p>
                <div className="flex flex-col xl:flex-row justify-between xl:h-[350px] mt-[20px] xl:mt-[50px]">
                    <div className="xl:w-[600px]">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.82')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.83')}</p>
                    </div>
                    <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.84')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.85')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Making;
import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/Raise/fem.png'

import Button from '../Button'

function Bond () {

    const { t } = useTranslation();
      
    return(
        <div className="bg-[#00685E] xl:h-[442px] mt-[80px] xl:mt-[160px]">
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col-reverse md:flex-row justify-between">
                <div className="relative">
                    <div className="bg-gradient-to-b from-[#00685E] to-transparent h-[100px] w-full absolute top-[0px] block md:hidden"></div>
                    <img src={img} alt="" className="w-full h-full object-cover"/>
                </div>     
                <div className="flex flex-col justify-between py-[80px]">
                    <p className="roboto text-white leading-[1.2] text-[24px] xl:text-[48px]">{t('raise.93')}</p>
                    <p className="text-[#FFFFFFB2] max-w-[600px] text-[16px] xl:text-[20px] mt-[20px] xl:mt-0">{t('raise.94')}</p>
                    <Button text={t('raise.95')} color="#FFF" textcolor="#000" className="mt-[20px] xl:mt-0"/>
                </div>
            </div>
        </div>
    );
}

export default Bond;
import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/Raise/image.png'

function Targeting () {

    const { t } = useTranslation();
      
    return(
        <>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div>
                    <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.60')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] roboto font-[500] text-[#000000B2] text-[20px] xl:text-[24px]">{t('raise.61')}</p>
                </div>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col gap-y-[20px] xl:flex-row justify-between pt-[20px] xl:pt-[40px]">
                <div className="xl:w-[393px] h-[334px] bg-[#EBF6F5] px-[40px] py-[20px] flex flex-col justify-between">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <g clip-path="url(#clip0_4864_658)">
                            <path d="M20 17.9688C24.9539 17.9688 28.9844 13.9383 28.9844 8.98438C28.9844 4.03046 24.9539 0 20 0C15.0461 0 11.0156 4.03046 11.0156 8.98438C11.0156 13.9383 15.0461 17.9688 20 17.9688Z" fill="#00685E"/>
                            <path d="M24.2615 17.9688H23.5156V23.7122L25.0552 31.4108C25.1392 31.8304 24.9878 32.2626 24.66 32.5378L20.7538 35.8191C20.5359 36.0022 20.2679 36.0938 20 36.0938C19.7321 36.0938 19.4641 36.0022 19.2462 35.8191L15.34 32.5378C15.0122 32.2626 14.8608 31.8304 14.9445 31.4108L16.4844 23.7122V17.9688H15.7385C8.8266 17.9688 3.20312 24.1019 3.20312 31.6406V38.8281C3.20312 39.4754 3.72772 40 4.375 40H35.625C36.2723 40 36.7969 39.4754 36.7969 38.8281V31.6406C36.7969 24.1019 31.1734 17.9688 24.2615 17.9688Z" fill="#00685E"/>
                            <path d="M17.3789 31.1899L20.0001 33.3914L22.6212 31.1899L21.3831 25H18.617L17.3789 31.1899Z" fill="#00685E"/>
                            <path d="M18.8281 20.3125H21.1719V22.6562H18.8281V20.3125Z" fill="#00685E"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4864_658">
                            <rect width="40" height="40" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('raise.62')}</p> 
                    <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('raise.63')}</p>
                </div>
                <div className="xl:w-[393px] h-[334px] bg-[#EBF6F5] px-[40px] py-[20px] flex flex-col justify-between">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <g clip-path="url(#clip0_4864_664)">
                            <path d="M31.8348 32.4814C31.6843 33.0742 31.4887 33.6561 31.248 34.225C30.6393 35.6643 29.7705 36.9578 28.6661 38.0706H40V32.4814H31.8348Z" fill="#00685E"/>
                            <path d="M8.75195 34.225C8.51141 33.6562 8.3157 33.0742 8.16516 32.4814H0V38.0706H11.3339C10.2295 36.9578 9.36078 35.6643 8.75195 34.225Z" fill="#00685E"/>
                            <path d="M7.80982 30.1379C7.79802 29.9169 7.79162 29.6951 7.79162 29.4723C7.79162 27.95 8.06826 26.4689 8.61318 25.0637V14.3838H2.42419V30.1379H7.80982Z" fill="#00685E"/>
                            <path d="M11.3673 20.8396C12.4878 19.7192 13.7932 18.8393 15.2472 18.2243C16.7538 17.587 18.3529 17.2639 20 17.2639C21.6471 17.2639 23.2461 17.587 24.7528 18.2243C26.2068 18.8393 27.5122 19.7193 28.6326 20.8396C28.7733 20.9803 28.9101 21.1238 29.0431 21.2702V14.3838H10.9568V21.2702C11.0899 21.1238 11.2267 20.9803 11.3673 20.8396Z" fill="#00685E"/>
                            <path d="M31.3869 14.3838V25.0636C31.9318 26.4689 32.2085 27.95 32.2085 29.4723C32.2085 29.6951 32.2021 29.9169 32.1903 30.1379H37.5758V14.3838H31.3869Z" fill="#00685E"/>
                            <path d="M40 12.0399V6.75457L20 0.663086L0 6.75457V12.0399H40ZM25.3698 8.27301H14.6303V5.92926H25.3698V8.27301H25.3698Z" fill="#00685E"/>
                            <path d="M29.8647 29.4721C29.8647 24.024 25.4481 19.6074 20 19.6074C14.5519 19.6074 10.1353 24.024 10.1353 29.4721C10.1353 34.9202 14.5519 39.3368 20 39.3368C25.4481 39.3368 29.8647 34.9202 29.8647 29.4721ZM16.7467 33.36L18.0862 31.4367C18.8281 31.9534 19.3961 32.1715 19.9905 32.1681C20.5809 32.1642 20.7964 31.775 20.8618 31.6078C20.9229 31.4513 20.9138 31.2609 20.8366 31.0855C20.768 30.9292 20.6154 30.7156 20.2787 30.5852C19.1979 30.1663 18.4981 29.87 17.8946 29.289C17.1895 28.6102 16.8734 27.6066 17.0695 26.6698C17.2676 25.7244 17.9314 24.9355 18.8263 24.5601V23.0046H21.1701V24.4771C21.8912 24.7029 22.521 25.1277 22.9803 25.587L21.3232 27.2444C21.0302 26.9514 20.3761 26.5074 19.7819 26.7021C19.5426 26.7805 19.3972 26.9895 19.3636 27.1504C19.3384 27.2705 19.3767 27.4626 19.5201 27.6007C19.7975 27.8677 20.2435 28.058 21.1257 28.4C21.9734 28.7285 22.6326 29.3473 22.9822 30.1423C23.3081 30.8837 23.3309 31.7289 23.0448 32.4612C22.7 33.3434 22.0224 33.9933 21.1702 34.3052V35.9397H18.8265V34.3663C18.1488 34.193 17.4685 33.8627 16.7467 33.36Z" fill="#00685E"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4864_664">
                            <rect width="40" height="40" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('raise.64')}</p> 
                    <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('raise.65')}</p>
                </div>
                <div className="xl:w-[393px] h-[334px] bg-[#EBF6F5] px-[40px] py-[20px] flex flex-col justify-between">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M27.8081 4.30176C27.5975 2.85652 26.3374 1.7666 24.877 1.7666H15.1231C13.6626 1.7666 12.4025 2.85652 12.192 4.30184L11.6775 7.83488H14.1223L14.586 4.65043C14.6246 4.38559 14.8555 4.1859 15.1231 4.1859H24.877C25.1445 4.1859 25.3755 4.38566 25.414 4.65043L25.8777 7.8348H28.3226L27.8081 4.30176Z" fill="#00685E"/>
                        <path d="M35.6601 25.4537H25.1867V26.8658C25.1867 27.3126 24.9405 27.7229 24.5463 27.9331L20.5693 30.0541C20.3914 30.1489 20.1958 30.1964 20 30.1964C19.8044 30.1964 19.6086 30.149 19.4307 30.0541L15.4537 27.9331C15.0595 27.7229 14.8133 27.3126 14.8133 26.8658V25.4537H4.33992C2.68898 25.4537 1.17508 24.8579 0 23.8711V36.7492C0 37.5678 0.666016 38.2339 1.48469 38.2339H38.5153C39.3339 38.2339 40 37.5679 40 36.7492V23.8711C38.8248 24.8579 37.3109 25.4537 35.6601 25.4537Z" fill="#00685E"/>
                        <path d="M38.5153 10.2539H1.48469C0.666016 10.2539 0 10.92 0 11.7386V18.6946C0 21.0877 1.94687 23.0345 4.33992 23.0345H14.8133V20.9155C14.8133 20.2475 15.3549 19.7059 16.023 19.7059H23.9771C24.6452 19.7059 25.1868 20.2475 25.1868 20.9155V23.0345H35.6601C38.0531 23.0345 40 21.0877 40 18.6946V11.7386C40 10.92 39.334 10.2539 38.5153 10.2539Z" fill="#00685E"/>
                        <path d="M17.2327 22.125V26.1399L20 27.6158L22.7673 26.1399V22.125H17.2327Z" fill="#00685E"/>
                    </svg>
                    <p className="roboto text-[#00685E] text-[20px] xl:text-[24px] font-[500]">{t('raise.66')}</p> 
                    <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('raise.67')}</p>
                </div>
            </div>
        </>
    );
}

export default Targeting;
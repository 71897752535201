import logo from './img/logo.svg';
import q1 from './img/Home/main.png';
import q2 from './img/Media/main.png';
import q3 from './img/Regulation/main.png';
import q4 from './img/Trading/main.png';
import q5 from './img/Raise/main.png';
import q6 from './img/Investing/main.png';
import q7 from './img/Listing/main.png';
import q8 from './img/About/main.png';

export const images = [
    logo, q1, q2, q3, q4, q5, q6, q7, q8
];
import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Home/main.png'

import Button from '../Button'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative h-[662px]">
            <img src={main} alt="" className="w-full h-full object-cover"/>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="absolute top-[100px]">
                    <p className="text-white font-[500] text-[32px] xl:text-[64px] leading-[1.2]"><Trans>{t('home.1')}</Trans></p>
                    <p className="text-[#FFFFFFB2] xl:max-w-[517px] mt-[20px] roboto text-[16px] xl:text-[20px]">{t('home.2')}</p>
                    <Button className="mt-[60px]" text={t('home.3')} color="#FFF" textcolor="#000"/>
                </div>
            </div>
        </div>
    );
}

export default Main;
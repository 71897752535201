import React from "react";

import { useTranslation } from "react-i18next";

import b1 from '../../img/About/b1.png'

function Business () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="xl:max-w-[596px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.5')}</p>
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#00685E] mt-[20px] xl:mt-[40px]">{t('about.6')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('about.7')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={b1} alt="" />
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="xl:max-w-[596px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.8')}</p>
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#000000B2] mt-[20px] xl:mt-[40px]">{t('about.9')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('about.10')}</p>
                </div>
                <div className="xl:max-w-[596px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.11')}</p>
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#000000B2] mt-[20px] xl:mt-[40px]">{t('about.12')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('about.13')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="xl:max-w-[596px]">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#000000B2] mt-[20px] xl:mt-[40px]">{t('about.14')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('about.15')}</p>
                    <p className="mt-[20px] xl:mt-[40px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.16')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.17')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.18')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.19')}</p>
                </div>
                <div className="xl:max-w-[596px]">
                    <p className="roboto font-[500] text-[20px] xl:text-[24px] text-[#000000B2] mt-[20px] xl:mt-[40px]">{t('about.20')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('about.21')}</p>
                    <p className="mt-[20px] xl:mt-[40px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.22')}</p>
                    <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.23')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <div className="xl:max-w-[596px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.24')}</p>
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400] mt-[20px]">{t('about.25')}</p>  
                </div>
                <div className="xl:w-[596px] bg-[#EBF6F5] p-[20px] xl:p-[40px] mt-[20px] xl:mt-0">
                    <p className="text-[16px] xl:text-[20px] font-[500] roboto text-[#00685E]">{t('about.26')}</p>
                    <p className="text-[#000000B2] text-[14px] xl:text-[16px] mt-[8px]">{t('about.27')}</p>
                    <p className="text-[16px] xl:text-[20px] font-[500] roboto text-[#00685E] mt-[20px]">{t('about.28')}</p>
                    <p className="text-[#000000B2] text-[14px] xl:text-[16px] mt-[8px]">{t('about.29')}</p>
                </div>
            </div>
        </div>
    );
}

export default Business;
import React from "react";

import { useTranslation } from "react-i18next";

import image from '../../img/About/image.png'

function Presence () {

    const { t } = useTranslation();
      
    return(
        <div className="relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('about.32')}</p>
                <p className="text-[16px] xl:text-[24px] roboto text-[#00685E] xl:max-w-[517px] mt-[10px] font-[500]">{t('about.33')}</p>
                <div className="flex flex-col xl:flex-row justify-between xl:h-[200px] mt-[20px] xl:mt-[50px]">
                    <div className="xl:w-[600px]">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.34')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.35')}</p>
                    </div>
                    <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.36')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.37')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between xl:h-[200px] mt-[20px] xl:mt-[50px]">
                    <div className="xl:w-[600px]">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.38')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.39')}</p>
                    </div>
                    <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.40')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.41')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between xl:h-[200px] mt-[20px] xl:mt-[50px]">
                    <div className="xl:w-[600px]">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.42')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.43')}</p>
                    </div>
                    <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.44')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.45')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[50px]">
                    <div className="xl:w-[600px]">
                        <div className="bg-[#00685E] h-[60px] flex items-center px-[20px] xl:px-[40px]">
                            <p className="text-[#FFFFFF] roboto text-[20px] xl:text-[24px]">{t('about.46')}</p>
                        </div>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2]">{t('about.47')}</p>
                        <p className="mt-[20px] xl:mt-[40px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.48')}</p>
                        <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.49')}</p>
                        <p className="mt-[6px] roboto text-[#000000B2] text-[16px] xl:text-[20px]"><span className="mr-[15px] text-[#00685E]">●</span>{t('about.50')}</p>
                        <p className="mt-[20px] roboto text-[16px] xl:text-[20px] text-[#000000B2] italic">{t('about.51')}</p>
                    </div>
                    <div className="xl:w-[600px] mt-[20px] xl:mt-0">
                        <img src={image} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Presence;
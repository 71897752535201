import React, { useState } from "react";

import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import com from '../../img/Trading/com.png'

import Fade from 'react-reveal/Fade'

function Comprehensive () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
    
    const navigate = useNavigate();
  
    const handleClick = (index) => {
      setActive(index);
      navigate('/listing', { state: { active: index } });
    };
      
    return(
        <div className="overflow-hidden relative bg-[#EBF6F5]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[40px] xl:mt-[80px]">
                <div>
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[517px]">{t('trading.19')}</p>
                    <Fade left><img src={com} alt="" className="mt-[80px]"/></Fade>
                </div>
                <div className="pb-[40px] xl:pb-[80px] flex flex-col justify-between gap-[10px] mt-[20px] xl:mt-0">
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(0)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.20')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(1)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.21')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(2)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.22')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(3)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.23')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(4)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.24')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(5)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.25')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                    <div className="bg-[#D5E6E5] h-[60px] xl:w-[600px] flex items-center px-[40px] justify-between cursor-pointer" onClick={() => handleClick(6)}>
                        <p className="text-[#00685E] roboto text-[20px] xl:text-[24px]">{t('trading.26')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                            <path d="M27.6364 7.6364C27.9879 7.28493 27.9879 6.71508 27.6364 6.36361L21.9088 0.636043C21.5574 0.284571 20.9875 0.284571 20.636 0.636043C20.2846 0.987515 20.2846 1.55736 20.636 1.90883L25.7272 7L20.636 12.0912C20.2846 12.4426 20.2846 13.0125 20.636 13.364C20.9875 13.7154 21.5574 13.7154 21.9088 13.364L27.6364 7.6364ZM-1.57361e-07 7.9L27 7.9L27 6.1L1.57361e-07 6.1L-1.57361e-07 7.9Z" fill="#00685E"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Comprehensive;
import React from "react";

import { useTranslation } from "react-i18next";

import image from '../../img/Media/image.png'

function Commitment () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[600px]">
                    <p className="text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E] xl:max-w-[509px]">{t('media.8')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:max-w-[600px]">
                    <p className="text-[#000000B2] text-[16px] xl:text-[20px] font-[400]">{t('media.9')}</p>
                </div>
            </div>
            <div className="flex flex-wrap gap-[20px] justify-between pt-[20px] xl:pt-[60px]">
                <div className="xl:w-[600px] h-[306px] bg-[#EBF6F5] flex-grow px-[40px] py-[20px]">
                    <div className="flex items-center gap-[20px]">
                        <div className="w-[70px] h-[70px] bg-[#00685E] flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <g clip-path="url(#clip0_5035_56)">
                                    <path d="M5.83459 23.8359L0 29.6706V31.2783L2.72359 34.0019H4.33128L10.1659 28.1672V26.5595L7.44235 23.8359H5.83459Z" fill="white"/>
                                    <path d="M10.5582 20.7188L8.81836 22.4585L11.5419 25.1821L13.2825 23.4415C12.2536 22.663 11.3414 21.7509 10.5582 20.7188Z" fill="white"/>
                                    <path d="M21.9944 13.1579C22.5419 13.0692 22.9138 12.5534 22.8251 12.0059C22.7363 11.4584 22.2205 11.0865 21.673 11.1752C21.1255 11.264 20.7537 11.7798 20.8424 12.3273C20.9312 12.8748 21.4469 13.2467 21.9944 13.1579Z" fill="white"/>
                                    <path d="M21.8379 7.36823C18.6357 7.36823 15.7774 10.8096 14.7773 12.1631C15.992 13.9338 19.2163 17.0969 21.8379 16.957C25.0407 16.957 27.8997 13.514 28.899 12.1616C26.5409 8.95222 23.7792 7.31982 21.8379 7.36823ZM21.8379 15.1591C20.1856 15.1591 18.8413 13.8149 18.8413 12.1626C18.8413 10.5102 20.1856 9.16598 21.8379 9.16598C23.4902 9.16598 24.8345 10.5102 24.8345 12.1626C24.8345 13.8149 23.4902 15.1591 21.8379 15.1591Z" fill="white"/>
                                    <path d="M21.8379 0C15.121 0 9.67578 5.44518 9.67578 12.1621C9.67578 18.879 15.121 24.3242 21.8379 24.3242C28.5548 24.3242 34 18.879 34 12.1621C34 5.44518 28.5548 0 21.8379 0ZM21.8379 18.9486C16.3296 18.8387 12.6024 12.4652 12.3781 12.1621C12.6024 11.859 16.3296 5.48549 21.8379 5.37559C27.3461 5.48549 31.0734 11.859 31.2977 12.1621C30.7499 13.0156 27.1131 18.8302 21.8379 18.9486Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_56">
                                    <rect width="34" height="34" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className="roboto text-[20px] xl:text-[24px] font-[500] text-[#00685E]">{t('media.10')}</p>
                    </div>
                    <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] mt-[20px]">{t('media.11')}</p>
                </div>
                <div className="xl:w-[600px] h-[306px] bg-[#EBF6F5] flex-grow px-[40px] py-[20px]">
                    <div className="flex items-center gap-[20px]">
                        <div className="w-[70px] h-[70px] bg-[#00685E] flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <g clip-path="url(#clip0_5035_76)">
                                    <path d="M2.40833 22.4542C2.40833 20.4001 4.00385 18.4875 6.51667 18.4875C8.704 18.4875 10.4833 20.2668 10.4833 22.4542C10.4833 24.5082 8.88781 26.4208 6.375 26.4208C4.18767 26.4208 2.40833 24.6415 2.40833 22.4542ZM6.94167 27.4833H5.95C2.669 27.4833 0 30.1523 0 33.4333C0 33.7464 0.253583 34 0.566667 34H12.325C12.6381 34 12.8917 33.7464 12.8917 33.4333C12.8917 30.1523 10.2227 27.4833 6.94167 27.4833ZM27.625 26.4208C29.8123 26.4208 31.5917 24.6415 31.5917 22.4542C31.5917 20.4001 29.9961 18.4875 27.4833 18.4875C25.296 18.4875 23.5167 20.2668 23.5167 22.4542C23.5167 24.5082 25.1122 26.4208 27.625 26.4208ZM28.05 27.4833H27.0583C23.7773 27.4833 21.1083 30.1523 21.1083 33.4333C21.1083 33.7464 21.3619 34 21.675 34H33.4333C33.7464 34 34 33.7464 34 33.4333C34 30.1523 31.331 27.4833 28.05 27.4833ZM29.0417 3.68333H18.2042C16.6416 3.68333 15.3708 4.95408 15.3708 6.51667V13.3875C15.3708 14.9501 16.6416 16.2208 18.2042 16.2208H19.4678C20.2258 17.7018 21.726 18.5686 23.3538 18.6285C23.7307 18.6662 24.0889 18.2229 23.8822 17.8096C23.63 17.3039 23.4437 16.7705 23.329 16.2208H29.0417C30.6042 16.2208 31.875 14.9501 31.875 13.3875V6.51667C31.875 4.95408 30.6042 3.68333 29.0417 3.68333ZM22.4629 1.77813C22.0438 0.736667 21.0231 0 19.8333 0H2.83333C1.26855 0 0 1.26855 0 2.83333V11.0004C0 12.5623 1.27075 13.8337 2.83333 13.8337H7.31283C7.24384 14.6331 7.01739 15.4396 6.647 16.1805C6.45957 16.5553 6.7337 17 7.15417 17C8.8949 17 10.7564 15.8473 11.3673 13.8337H13.6718C13.9862 13.8337 14.2406 13.5782 14.2384 13.2637C14.2338 12.6052 14.2376 10.9715 14.2376 6.51716C14.2375 4.32643 16.0134 2.55 18.2042 2.55H21.9344C22.3328 2.55 22.6117 2.14774 22.4629 1.77813Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_76">
                                    <rect width="34" height="34" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className="roboto text-[20px] xl:text-[24px] font-[500] text-[#00685E]">{t('media.12')}</p>
                    </div>
                    <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] mt-[20px]">{t('media.13')}</p>
                </div>
                <div className="xl:w-[600px] h-[306px] bg-[#EBF6F5] flex-grow px-[40px] py-[20px]">
                    <div className="flex items-center gap-[20px]">
                        <div className="w-[70px] h-[70px] bg-[#00685E] flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <g clip-path="url(#clip0_5035_65)">
                                    <path d="M16.6135 6.64011H31.2921C31.6703 6.64011 31.977 6.3335 31.977 5.95503V1.14966C31.977 0.771454 31.6703 0.464844 31.2921 0.464844H14.3684C13.9902 0.464844 13.6836 0.771454 13.6836 1.14966V8.13995C13.6836 8.43567 14.0333 8.59261 14.2538 8.39546L15.9292 6.90106C16.1175 6.73323 16.3609 6.64011 16.6135 6.64011ZM22.6334 2.36079H28.9695C29.5373 2.36079 29.997 2.82019 29.997 3.38802C29.997 3.95506 29.5373 4.4155 28.9695 4.4155H22.6334C22.0661 4.4155 21.6059 3.95506 21.6059 3.38802C21.6059 2.82019 22.0661 2.36079 22.6334 2.36079ZM17.054 2.36079H18.8978C19.4656 2.36079 19.925 2.82019 19.925 3.38802C19.925 3.95506 19.4656 4.4155 18.8978 4.4155H17.054C16.4861 4.4155 16.0265 3.95506 16.0265 3.38802C16.0265 2.82019 16.4861 2.36079 17.054 2.36079Z" fill="white"/>
                                    <path d="M7.30183 6.62933L8.91011 7.83502C9.13579 8.00441 9.45796 7.84332 9.45796 7.5611V1.44653C9.45796 1.06833 9.15135 0.761719 8.77315 0.761719H0.684814C0.30661 0.761719 0 1.06833 0 1.44653V5.78551C0 6.16397 0.30661 6.47058 0.684814 6.47058H6.7532C6.94775 6.47058 7.13815 6.52531 7.30183 6.62933ZM2.86636 4.48825C2.29932 4.48825 1.83888 4.02808 1.83888 3.46103C1.83888 2.8932 2.29932 2.43355 2.86636 2.43355H6.33765C6.90521 2.43355 7.36487 2.8932 7.36487 3.46103C7.36487 4.02808 6.90521 4.48825 6.33765 4.48825H2.86636Z" fill="white"/>
                                    <path d="M0.648438 13.7889V27.3511H2.05257V21.7663C2.05257 19.6986 3.11507 17.8745 4.72256 16.8115C3.79729 15.668 3.34697 14.2242 3.45877 12.7617H1.67592C1.10861 12.7617 0.648438 13.2216 0.648438 13.7889Z" fill="white"/>
                                    <path d="M18.6607 18.3629C18.0026 18.9481 16.9489 18.4877 16.9489 17.5959V12.7617H14.9414C15.0532 14.2221 14.6039 15.6665 13.6779 16.8115C15.2854 17.8745 16.3476 19.6986 16.3476 21.7663V27.3511H28.5612V15.6649H21.6837L18.6607 18.3629ZM25.8624 25.8539H20.6523C20.0853 25.8539 19.6248 25.3937 19.6248 24.8266C19.6248 24.2593 20.0853 23.7992 20.6523 23.7992H25.8624C26.4297 23.7992 26.8898 24.2593 26.8898 24.8266C26.8898 25.3937 26.4297 25.8539 25.8624 25.8539ZM26.8898 21.3074C26.8898 21.8744 26.4297 22.3349 25.8624 22.3349H20.6523C20.0853 22.3349 19.6248 21.8744 19.6248 21.3074C19.6248 20.7395 20.0853 20.2799 20.6523 20.2799H25.8624C26.4297 20.2799 26.8898 20.7395 26.8898 21.3074Z" fill="white"/>
                                    <path d="M14.2929 21.7676C14.2929 20.1936 13.351 18.8356 12.0019 18.2266C11.1723 18.6904 10.2162 18.9555 9.19983 18.9555C8.18324 18.9555 7.22787 18.6904 6.39857 18.2266C5.04918 18.8349 4.1073 20.1931 4.1073 21.7676V27.3525H14.2929V21.7676Z" fill="white"/>
                                    <path d="M2.09978 29.4062H0.648438V32.607C0.648438 33.1743 1.10861 33.6342 1.67592 33.6342H27.5336C28.1009 33.6342 28.5611 33.1743 28.5611 32.607V29.4062H2.09978ZM21.583 30.6809H24.2548C24.8226 30.6809 25.2823 31.1406 25.2823 31.7082C25.2823 32.2755 24.8226 32.7356 24.2548 32.7356H21.583C21.0152 32.7356 20.5558 32.2755 20.5558 31.7082C20.5558 31.1406 21.0152 30.6809 21.583 30.6809Z" fill="white"/>
                                    <path d="M5.49805 13.1956C5.49805 15.2368 7.15976 16.8988 9.20071 16.8988C11.2375 16.8988 12.9039 15.2418 12.9039 13.1956C12.9039 11.1544 11.2445 9.49219 9.20071 9.49219C7.15587 9.49219 5.49805 11.1573 5.49805 13.1956Z" fill="white"/>
                                    <path d="M19.0039 9.45435V14.5386C19.0039 14.8343 19.3538 14.991 19.5743 14.7941L20.6088 13.8714C20.7964 13.7036 21.0402 13.6104 21.2923 13.6104H33.3155C33.6937 13.6104 34.0003 13.3038 34.0003 12.9256V9.45435C34.0003 9.07614 33.6937 8.76953 33.3155 8.76953H19.689C19.3108 8.76953 19.0039 9.07614 19.0039 9.45435ZM20.6342 11.0517C20.6342 10.4875 21.0913 10.0242 21.6615 10.0242H26.9711C27.5426 10.0242 27.9983 10.4886 27.9983 11.0517C27.9983 11.619 27.5389 12.0789 26.9711 12.0789H21.6615C21.0941 12.0789 20.6342 11.6198 20.6342 11.0517ZM31.6463 12.0789C30.7897 12.0789 30.9791 12.0789 30.101 12.0789C29.534 12.0789 29.0738 11.6196 29.0738 11.0517C29.0738 10.4857 29.5329 10.0242 30.101 10.0242H31.6463C32.2141 10.0242 32.6737 10.4844 32.6737 11.0517C32.6737 11.6196 32.2141 12.0789 31.6463 12.0789Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_5035_65">
                                    <rect width="34" height="34" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className="roboto text-[20px] xl:text-[24px] font-[500] text-[#00685E]">{t('media.14')}</p>
                    </div>
                    <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] mt-[20px]">{t('media.15')}</p>
                </div>
                <div className="xl:w-[600px] h-[306px] bg-[#EBF6F5] flex-grow px-[40px] py-[20px]">
                    <div className="flex items-center gap-[20px]">
                        <div className="w-[70px] h-[70px] bg-[#00685E] flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <path d="M17.5449 19.984V26.589C17.5449 26.9028 17.2905 27.1572 16.9767 27.1572H12.1464C11.8326 27.1572 11.5781 26.9028 11.5781 26.589V16.1332C11.5781 15.7178 12.109 15.5497 12.3517 15.8868C13.516 17.5042 15.1811 18.7762 17.1532 19.4439C17.386 19.5227 17.5449 19.7382 17.5449 19.984Z" fill="white"/>
                                <path d="M9.44742 15.6503V26.5894C9.44742 26.9032 9.19297 27.1577 8.87915 27.1577H4.04889C3.73506 27.1577 3.48062 26.9032 3.48062 26.5894V15.6503C3.48062 15.3365 3.73506 15.082 4.04889 15.082H8.87915C9.19297 15.082 9.44742 15.3365 9.44742 15.6503Z" fill="white"/>
                                <path d="M25.6426 20.2066V26.5899C25.6426 26.9037 25.3881 27.1581 25.0743 27.1581H20.244C19.9302 27.1581 19.6758 26.9037 19.6758 26.5899V20.3815C19.6758 20.1453 19.8691 19.951 20.1052 19.9554C21.5349 19.9813 22.9425 19.6972 24.2957 19.0866C24.5232 18.984 24.7906 19.0375 24.957 19.2234L25.4977 19.8276C25.591 19.9318 25.6426 20.0667 25.6426 20.2066Z" fill="white"/>
                                <path d="M20.2733 1.58594C15.5219 1.58594 11.6562 5.45157 11.6562 10.203C11.6562 14.9544 15.5219 18.82 20.2733 18.82C25.0247 18.82 28.8903 14.9544 28.8903 10.203C28.8903 5.45157 25.0247 1.58594 20.2733 1.58594ZM17.5449 14.9427C17.5449 15.4033 17.0265 15.6711 16.6488 15.4074C15.2811 14.4526 14.327 12.9861 14.0289 11.3265C13.9816 11.0633 14.1802 10.8203 14.4477 10.8203H16.9766C17.2905 10.8203 17.5449 11.0747 17.5449 11.3885V14.9427ZM25.6427 13.4093C25.6427 13.5193 25.612 13.6278 25.5509 13.7192C24.3371 15.5357 22.2704 16.6122 20.0912 16.5452C19.8602 16.5381 19.6759 16.3497 19.6759 16.1186V7.12653C19.6759 6.81271 19.9303 6.55827 20.2442 6.55827H25.0744C25.3883 6.55827 25.6427 6.81271 25.6427 7.12653V13.4093Z" fill="white"/>
                                <path d="M29.1236 29.9978V30.7081C29.1236 31.6479 28.3586 32.4129 27.4188 32.4129H1.7048C0.765028 32.4129 0 31.6479 0 30.7081V29.9978C0 29.058 0.765028 28.293 1.7048 28.293H27.4188C28.3586 28.293 29.1236 29.058 29.1236 29.9978Z" fill="white"/>
                                <path d="M33.5011 22.5912L32.6615 23.4308C31.9687 24.1236 30.8364 24.0889 30.1853 23.3619L26.0973 18.7931C25.8751 18.5447 25.9151 18.1617 26.1801 17.9596C26.8787 17.4269 27.4982 16.8072 28.0299 16.1099C28.232 15.8448 28.615 15.8048 28.8633 16.027L33.4321 20.1149C34.1567 20.764 34.196 21.8949 33.5011 22.5912Z" fill="white"/>
                            </svg>
                        </div>
                        <p className="roboto text-[20px] xl:text-[24px] font-[500] text-[#00685E]">{t('media.16')}</p>
                    </div>
                    <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] mt-[20px]">{t('media.17')}</p>
                </div>
            </div>
        </div>
    );
}

export default Commitment;
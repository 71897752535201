import React from "react";

import { useTranslation } from "react-i18next";

import img from '../../img/Raise/img2.png'

function TheWorld () {

    const { t } = useTranslation();
      
    return(
        <>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[160px]">
                <p className="xl:max-w-[600px] text-[20px] xl:text-[40px] roboto leading-[1.2] text-[#00685E]">{t('raise.96')}</p>
            </div>
            <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                <div>                 
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[24px] mt-[20px] font-[500] xl:mt-[40px]">{t('raise.97')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.98')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.99')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[24px] mt-[20px] font-[500] xl:mt-[40px]">{t('raise.100')}</p>
                    <p className="xl:max-w-[600px] text-[#000000B2] text-[14px] xl:text-[20px] mt-[20px] font-[400]">{t('raise.101')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[20px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.102')}</p>
                    <p className="xl:max-w-[600px] font-[500] text-[#000000B2] text-[16px] xl:text-[20px] mt-[3px]"><span className="text-[#00685E] mr-[20px]">•</span>{t('raise.103')}</p>
                </div>
            </div>
        </>
    );
}

export default TheWorld;